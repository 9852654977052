import SubmitButton from '@sportnet/ui/Button/Submit';
import FormField from '@sportnet/ui/FormField';
import Col, { Row } from '@sportnet/ui/Grid';
import HeaderBar from '@sportnet/ui/HeaderBar';
import { IIconName } from '@sportnet/ui/Icon';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import * as React from 'react';
import { connect } from 'react-redux';
import { getProp, setProp } from '@sportnet/utilities';
import ContentLoader from '../../../components/ContentLoader';
import { RootState } from '../../../rootReducer';
import { __ } from '../../../utilities';
import { loadSettings, saveSettings } from '../../Editor/actions';
import {
  settingsIsFetchingSelector,
  settingsSelector,
} from '../../Editor/selectors';
import socialNetworks from './networks';
import { useAuth } from '@sportnet/auth-react';

type IOwnProps = {};

const mapStateToProps = (state: RootState) => ({
  settings: settingsSelector(state),
  settingsIsFetching: settingsIsFetchingSelector(state),
});

const mapDispatchToProps = {
  saveSettings: saveSettings.action,
  loadSettings: loadSettings.action,
};

type IProps = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const C: React.FC<IProps> = ({
  loadSettings,
  saveSettings,
  settings,
  settingsIsFetching,
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [hasSubmitFailed, setHasSubmitFailed] = React.useState(false);
  const [social, setSocial] = React.useState<{
    [key: string]: any;
  }>({});

  const { ppo: appSpace } = useAuth();

  React.useEffect(() => {
    if (!appSpace) {
      return;
    }
    loadSettings({ appSpace });
  }, [loadSettings, appSpace]);

  React.useEffect(() => {
    const s = getProp(settings, ['social'], {});
    setSocial(s);
  }, [settings]);

  async function handleSubmit() {
    if (appSpace) {
      try {
        setIsSubmitting(true);
        setHasSubmitFailed(false);
        await saveSettings({
          appSpace,
          settings: {
            social: social as any,
          },
        });
        setHasSubmitFailed(false);
      } catch (e) {
        setHasSubmitFailed(true);
      } finally {
        setIsSubmitting(false);
      }
    }
  }

  return (
    <ScrollLayout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__('Sociálne siete')}</HeaderBar.Header>
        </HeaderBar>
      }
    >
      {settingsIsFetching && !settings ? (
        <ContentLoader />
      ) : (
        <Segment noBottomGutter>
          <Row>
            {socialNetworks.map(item => (
              <Col key={item.value} xs={12} s={6} l={4}>
                <Segment
                  paddingSize="l"
                  raised
                  header={
                    <SegmentHeader size="s" icon={item.icon as IIconName}>
                      {item.label}
                    </SegmentHeader>
                  }
                >
                  {item.fields.map(field => (
                    <FormField
                      key={field.name}
                      label={field.label}
                      value={getProp(social, [item.value, field.name])}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const v = e.target.value;
                        setSocial(prev =>
                          setProp(prev, [item.value, field.name], v),
                        );
                      }}
                      placeholder={field.placeholder}
                    />
                  ))}
                </Segment>
              </Col>
            ))}
          </Row>
          <SubmitButton
            successText={__('Uložené!')}
            onClick={handleSubmit}
            isSubmitting={isSubmitting}
            isError={hasSubmitFailed}
          >
            {__('Uložiť zmeny')}
          </SubmitButton>
        </Segment>
      )}
    </ScrollLayout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(C as any);
