import { __ } from '../../../../utilities';

export const socialOptions = [
  {
    label: __('Nezobrazovať'),
    value: 'x',
  },
  {
    label: __('Zobraziť svetlé varianty'),
    value: 'light',
  },
  {
    label: __('Zobraziť tmavé varianty'),
    value: 'dark',
  },
  {
    label: __('Zobraziť plnofarbené varianty'),
    value: 'color',
  },
];

export const FORM_NAME = 'layout-form';
