enum HTTP_CODES {
  ERROR = 500,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  METHOD_NOT_ALLOWED = 405,
  CONFLICT = 409,
}

export class RESTError extends Error {
  public statusCode: HTTP_CODES;
  public name: string;
  public description?: string;
  public payload?: any;
  public userinfo?: string;

  constructor(
    statusCode: HTTP_CODES = HTTP_CODES.ERROR,
    name = 'UNKNOWN_ERROR',
    description?: string,
    payload?: any,
    userinfo?: string,
  ) {
    super(description || name);
    this.name = name;
    this.statusCode = statusCode;
    this.description = description;
    this.payload = payload;
    this.userinfo = userinfo;
  }
}

export interface ApiError {
  message: string;
  details: {
    statusCode: number;
    name: string;
    description: string | null;
    payload?: unknown;
    userinfo?: string;
  };
}

export interface GenericApiError {
  message: string;
  details: {
    name: string;
    description: string | null;
    payload?: unknown;
    userinfo?: string;
  } & ({ statusCode: number } | { code: number });
}

export type CmsApiError = {
  code: number;
  name: string;
  description: string | null;
  payload?: object | null;
  userinfo?: string;
};

export const isGenericApiError = (error: unknown): error is GenericApiError => {
  return typeof (error as GenericApiError).details !== 'undefined';
};

export const isRESTError = (
  errorDetails: unknown,
): errorDetails is RESTError => {
  return (errorDetails as RESTError).statusCode !== undefined;
};

export const isCmsApiError = (
  errorDetails: unknown,
): errorDetails is CmsApiError => {
  return typeof (errorDetails as CmsApiError).code !== 'undefined';
};

export const getApiError = (error?: unknown): ApiError => {
  if (isGenericApiError(error)) {
    if (isCmsApiError(error.details)) {
      const { code: statusCode, ...restDetails } = error.details;
      return {
        ...error,
        details: {
          ...restDetails,
          statusCode,
        },
      };
    } else if (isRESTError(error.details)) {
      return {
        message: error.details.description,
        details: {
          statusCode: error.details.statusCode,
          name: error.details.name,
          description: error.details.description,
          payload: error.details.payload,
        },
      };
    }
  }
  return {
    message: (error as Error).message,
    details: {
      statusCode: 500,
      name: 'OTHER_EXCEPTION',
      description: (error as Error).message,
    },
  };
};
