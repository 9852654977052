import { IAppPublic } from '@sportnet/ui/Authorization/types';
import {
  getListParameters,
  initialize,
  ListParameters,
  setParams,
} from '@sportnet/redux-list/ducks';
import actionCreatorFactory from 'typescript-fsa';
import asyncFactory from 'typescript-fsa-redux-thunk';
import { CustomThunkDispatch, ExtraArgumentType } from '../../configureStore';
import {
  IApplicationInfo,
  IAppSpace,
  IAuthorizationResponse,
  Writeable,
} from '../../library/App';
import { RootState } from '../../rootReducer';
import { updateEntities as updateEntitiesUtility } from '../../utilities';
import { entitiesSelector } from './selectors';

const create = actionCreatorFactory('APP');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const setAppspaces = create<IAppSpace[]>('SET_APPSPACES');
export const setApps = create<IAppPublic[]>('SET_APPS');

export const setActiveAppspace = create<string>('SET_ACTIVE_APPSPACE');

export const setToken = create<string>('SET_TOKEN');

export const removeActiveAppspace = create('REMOVE_ACTIVE_APPSPACE');

export const setAuthUser = create<IAuthorizationResponse>('SET_AUTH_USER');

export const setApplicationInfo = create<IApplicationInfo>(
  'SET_APPLICATION_INFO',
);

export const setBreadcrumbs =
  create<Array<JSX.Element | string>>('SET_BREADCRUMBS');

export const updateEntities = (
  entitiesToUpdate: Partial<RootState['entities']>,
) => {
  const entities = entitiesToUpdate as Writeable<typeof entitiesToUpdate>;
  return (dispatch: CustomThunkDispatch, getState: () => RootState) => {
    const oldEntities = entitiesSelector(getState());
    const nextEntities = updateEntitiesUtility(oldEntities, entities);
    return dispatch({
      type: 'UPDATE_ENTITIES',
      payload: {
        result: {
          entities: nextEntities,
        },
      },
    });
  };
};

export const replaceEntities = (entities: Partial<RootState['entities']>) => {
  return {
    type: 'REPLACE_ENTITIES',
    payload: {
      result: {
        entities,
      },
    },
  };
};

export const initializeOrSetListParams = createAsync<
  {
    listName: string;
    params: ListParameters;
  },
  any
>('INITIALIZE_OR_SET_LIST_PARAMS', async (parameters, dispatch, getState) => {
  const reduxListParams = getListParameters(parameters.listName)(getState());
  if (Object.keys(reduxListParams).length === 0) {
    return dispatch(
      initialize({
        listName: parameters.listName,
        initialParams: parameters.params,
      }),
    );
  }
  return dispatch(
    setParams({
      listName: parameters.listName,
      parameters: parameters.params,
    }),
  );
});
