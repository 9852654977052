import { lighten, rem } from 'polished';
import React from 'react';
import styled from '../../theme/styled-components';

const Wrapper = styled.span<{ color: string }>`
  padding: ${rem(2)} ${rem(4)};
  border-radius: ${({ theme }) => theme.largeBorderRadius};

  font-size: ${rem(11)};
  display: inline-flex;
  flex-shrink: 0;

  background-color: ${({ color }) => lighten(0.37, color)};
  color: ${({ color }) => color};
  border: 1px solid ${({ color }) => color};
`;

type IOwnProps = {
  color: string;
  className?: string;
  children?: React.ReactNode;
};

type IProps = IOwnProps;

const Tag: React.FC<IProps> = ({ color, children, className }) => {
  return (
    <Wrapper className={className} color={color}>
      {children}
    </Wrapper>
  );
};

export default Tag;
