import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { ExtraArgumentType } from '../../../configureStore';
import {
  IAdminCreateLayout,
  IAdminEditLayout,
  ILayout,
} from '../../../library/Pages';
import { RootState } from '../../../rootReducer';
import { replaceEntities } from '../../App/actions';

const create = actionCreatorFactory('LAYOUT');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadDetail = createAsync<
  { appSpace: string; layoutId: string },
  ILayout
>(
  'LOAD_DETAIL',
  async ({ appSpace, layoutId }, dispatch, getState, { PagesApi }) => {
    const response = await PagesApi.adminGetLayout(appSpace, layoutId);
    dispatch(
      replaceEntities({
        layouts: { [response._id!]: response }, // we are sure _id will be there
      }),
    );

    return response;
  },
);

export const saveLayout = createAsync<
  | { appSpace: string; layoutId: string; values: IAdminEditLayout }
  | { appSpace: string; values: IAdminCreateLayout; layoutId: undefined },
  ILayout
>(
  'SAVE',
  async ({ appSpace, values, layoutId }, dispatch, getState, { PagesApi }) => {
    let response: ILayout;

    if (layoutId) {
      response = await PagesApi.adminEditLayout(appSpace, layoutId, {}, values);
    } else {
      response = await PagesApi.adminCreateLayout(appSpace, {}, values);
    }

    dispatch(
      replaceEntities({
        layouts: {
          [response._id!]: response, // we are sure _id will be there
        },
      }),
    );

    return response;
  },
);

export const deleteLayout = createAsync<
  { appSpace: string; layoutId: string },
  void
>(
  'DELETE',
  async ({ appSpace, layoutId }, dispatch, getState, { PagesApi }) => {
    await PagesApi.adminDeleteLayout(appSpace, layoutId);
  },
);
