import FormField from '@sportnet/ui/FormField/redux-form';
import * as React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import { RootState } from '../../../../../rootReducer';
import { __ } from '../../../../../utilities';
import HeaderFutbalsfzCorporateFields from './HeaderFutbalsfzCorporateFields';
import HeaderFutbalsfzFields from './HeaderFutbalsfzFields';
import HeaderSimpleFields from './HeaderSimpleFields';

const headerOptions = [
  {
    label: __('Žiadna'),
    value: 'x',
  },
  {
    label: __('Jednoduchá hlavička'),
    value: 'simple-1',
  },
  {
    label: __('Hlavička s plachtou'),
    value: 'futbalsfz',
  },
  {
    label: __('Korporátna hlavička s plachtou'),
    value: 'futbalsfz-corporate',
  },
];

interface IOwnProps {
  formName: string;
}

const mapStateToProps = (state: RootState, { formName }: IOwnProps) => {
  const selector = formValueSelector(formName);
  return {
    headerType: selector(state, 'settings.header.type'),
  };
};

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

const HeaderFields: React.FC<IProps> = ({ headerType }) => {
  let headerFields = null;

  switch (headerType) {
    case 'simple-1':
      headerFields = <HeaderSimpleFields />;
      break;
    case 'futbalsfz':
      headerFields = <HeaderFutbalsfzFields />;
      break;
    case 'futbalsfz-corporate':
      headerFields = <HeaderFutbalsfzCorporateFields />;
      break;
    default:
      break;
  }

  return (
    <>
      <Field
        component={FormField}
        name="settings.header.type"
        type="theselectsimple"
        label={__('Typ')}
        options={headerOptions}
        format={(value = '') => value || 'x'}
        parse={(value: string) => (value === 'x' ? '' : value)}
        clearable={false}
      />
      {headerFields}
    </>
  );
};

export default connect(mapStateToProps)(HeaderFields);
