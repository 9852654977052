import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import configureStore from './configureStore';
import App from './pages/App';
import GlobalStyle from './theme/globalStyles';
import { ThemeProvider } from './theme/styled-components';
import { theme } from './theme/theme';
import { createRoot } from 'react-dom/client';
import React from 'react';

const store = configureStore();
const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router>
        <App />
        <GlobalStyle />
      </Router>
    </ThemeProvider>
  </Provider>,
);
