import SubmitButton from '@sportnet/ui/Button/Submit';
import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, getFormValues, initialize, reduxForm } from 'redux-form';
import { getProp } from '@sportnet/utilities';
import ContentLoader from '../../../components/ContentLoader';
import { IAdminEditSettings } from '../../../library/Pages';
import { RootState } from '../../../rootReducer';
import { __ } from '../../../utilities';
import { activeAppspaceIdSelector } from '../../App/selectors';
import {
  deleteSettingsSegment,
  loadSettings,
  saveSettings,
} from '../../Editor/actions';
import {
  settingsIsFetchingSelector,
  settingsSelector,
} from '../../Editor/selectors';
import { useAuth } from '@sportnet/auth-react';

const FORM_NAME = 'analytics-form';

type IOwnProps = {};

const mapStateToProps = (state: RootState) => ({
  activeAppspaceId: activeAppspaceIdSelector(state),
  settings: settingsSelector(state),
  settingsIsFetching: settingsIsFetchingSelector(state),
  values: getFormValues(FORM_NAME)(state) as {
    googleAnalytics?: {
      id: string;
    };
    googleTagmanager?: {
      id: string;
    };
  },
});

const mapDispatchToProps = {
  saveSettings: saveSettings.action,
  initialize,
  loadSettings: loadSettings.action,
  deleteSettingsSegment: deleteSettingsSegment.action,
};

type IProps = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const C: React.FC<IProps> = ({
  saveSettings,
  values,
  initialize,
  settings,
  deleteSettingsSegment,
  loadSettings,
  settingsIsFetching,
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [hasSubmitFailed, setHasSubmitFailed] = React.useState(false);

  const { ppo: appSpace } = useAuth();

  React.useEffect(() => {
    if (!appSpace) {
      return;
    }
    loadSettings({ appSpace });
  }, [loadSettings, appSpace]);

  React.useEffect(() => {
    initialize(FORM_NAME, {
      googleAnalytics: getProp(settings, ['googleAnalytics']),
      googleTagmanager: getProp(settings, ['googleTagmanager']),
    });
  }, [settings, initialize]);

  async function handleSubmit() {
    if (appSpace) {
      try {
        setIsSubmitting(true);
        setHasSubmitFailed(false);
        const googleAnalyticsId = getProp(values, ['googleAnalytics', 'id']);
        const googleTagmanagerId = getProp(values, ['googleTagmanager', 'id']);

        const valuesToSave: IAdminEditSettings = {};
        if (googleAnalyticsId) {
          valuesToSave.googleAnalytics = {
            id: googleAnalyticsId,
          };
        } else {
          await deleteSettingsSegment({
            appSpace,
            settingsSegment: 'googleAnalytics',
          });
        }
        if (googleTagmanagerId) {
          valuesToSave.googleTagmanager = {
            id: googleTagmanagerId,
          };
        } else {
          await deleteSettingsSegment({
            appSpace,
            settingsSegment: 'googleTagmanager',
          });
        }
        if (Object.keys(valuesToSave).length > 0) {
          await saveSettings({
            appSpace,
            settings: valuesToSave,
          });
        }
        setHasSubmitFailed(false);
      } catch (e) {
        setHasSubmitFailed(true);
      } finally {
        setIsSubmitting(false);
      }
    }
  }

  return (
    <ScrollLayout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__('Analytika')}</HeaderBar.Header>
        </HeaderBar>
      }
    >
      {settingsIsFetching && !settings ? (
        <ContentLoader />
      ) : (
        <Segment noBottomGutter>
          <Row>
            <Col xs={12} s={6} l={4}>
              <Segment
                paddingSize="l"
                raised
                header={
                  <SegmentHeader size="s" icon="google-analytics">
                    Google Analytics
                  </SegmentHeader>
                }
              >
                <Field
                  component={FormField}
                  name="googleAnalytics.id"
                  label={__('Identifikátor')}
                  placeholder={__('napr. UA-00000000-0')}
                />
              </Segment>
            </Col>
            <Col xs={12} s={6} l={4}>
              <Segment
                paddingSize="l"
                raised
                header={
                  <SegmentHeader size="s" icon="google-tag-manager">
                    Google tag Manager
                  </SegmentHeader>
                }
              >
                <Field
                  component={FormField}
                  name="googleTagmanager.id"
                  label={__('Identifikátor')}
                  placeholder={__('napr. GTM-0000')}
                />
              </Segment>
            </Col>
          </Row>
          <SubmitButton
            successText={__('Uložené!')}
            onClick={handleSubmit}
            isSubmitting={isSubmitting}
            isError={hasSubmitFailed}
          >
            {__('Uložiť zmeny')}
          </SubmitButton>
        </Segment>
      )}
    </ScrollLayout>
  );
};

export default compose(
  reduxForm({
    form: FORM_NAME,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(C as any);
