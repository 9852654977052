import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Analytics from './Analytics';
import ContactForm from './ContactForm';
import Domain from './Domain';
import Homepage from './Homepage';
import Social from './Social';

const Settings: React.FC = () => {
  return (
    <Switch>
      <Route path="/admin/:appspace/domain" component={Domain} />
      <Route path="/admin/:appspace/homepage" component={Homepage} />
      <Route path="/admin/:appspace/analytics" component={Analytics} />
      <Route path="/admin/:appspace/contact-form" component={ContactForm} />
      <Route path="/admin/:appspace/social" component={Social} />
    </Switch>
  );
};

export default Settings;
