import { createSelector } from 'reselect';
import {
  INormalizedSectionTree,
  ISectionTree,
  SectionId,
} from '../../library/App';
import { RootState } from '../../rootReducer';
import { normalizeSectionParent } from '../../utilities';
import { entitiesSelector } from '../App/selectors';

// Section Tree
const sectionTreeDomain = (state: RootState) => state.sectionTree;

export const sectionTreeTreeSelector = createSelector(
  sectionTreeDomain,
  treeDomain => treeDomain.tree,
);

export const sectionTreeIsFetchingSelector = createSelector(
  sectionTreeDomain,
  treeDomain => treeDomain.isFetching,
);

export const sectionTreeSelector = createSelector(
  sectionTreeTreeSelector,
  entitiesSelector,
  (tree, entities) => {
    const traverseTree = (
      sections: Readonly<INormalizedSectionTree>,
    ): ISectionTree => {
      return sections.map(section => {
        if (section.sections && section.sections.length > 0) {
          return {
            ...entities.sections[section._id],
            sections: traverseTree(section.sections),
          };
        }
        return entities.sections[section._id];
      });
    };

    return traverseTree(tree);
  },
);

const settingsDomain = (state: RootState) => state.settings;

export const settingsSelector = createSelector(
  settingsDomain,
  domain => domain.settings,
);

export const settingsIsFetchingSelector = createSelector(
  settingsDomain,
  domain => domain.isFetching,
);

export const sectionEntitiesSelector = createSelector(
  entitiesSelector,
  entities => entities.sections,
);

export const sectionsOptionsSelector = createSelector(
  sectionEntitiesSelector,
  sectionEntities => {
    return Object.keys(sectionEntities).map(id => ({
      value: id,
      label: sectionEntities[id].name,
    }));
  },
);

interface ISectionTreeOption {
  value: string | number;
  label: string;
  level: number;
  parent: SectionId[];
}

interface ISectionTreeNode {
  _id: SectionId;
  name?: string;
  sections?: ReadonlyArray<ISectionTreeNode>;
  parent?: SectionId[] | SectionId | null;
}

export const sectionTreeOptionsSelector = createSelector(
  sectionTreeSelector,
  (tree): ISectionTreeOption[] => {
    const result: ISectionTreeOption[] = [];

    const traverseTree = (
      sections: ReadonlyArray<ISectionTreeNode>,
      level = 0,
    ) => {
      sections.forEach(section => {
        result.push({
          value: section._id,
          label: section.name!,
          level,
          parent: normalizeSectionParent(section.parent),
        });
        if (section.sections && section.sections.length > 0) {
          traverseTree(section.sections, level + 1);
        }
      });
    };

    traverseTree(tree as ReadonlyArray<ISectionTreeNode>);
    return result;
  },
);
