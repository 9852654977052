import DefaultFilter from '@sportnet/ui/Filter';
import React from 'react';

interface IQuery {
  q: string;
}

interface IOwnProps {
  query: IQuery;
  setQuery: (value: IQuery) => void;
}

type IProps = IOwnProps;

const SectionFilter: React.FC<IProps> = ({ query, setQuery }) => {
  const filterValue = {
    q: query.q,
  };

  return (
    <DefaultFilter
      value={filterValue}
      onChange={nextFilterValue => {
        setQuery({
          q: nextFilterValue.q ? (nextFilterValue.q as string) : '',
        });
      }}
      filters={[
        {
          type: 'query' as const,
          name: 'q',
        },
      ]}
    />
  );
};

export default SectionFilter;
