import FormField from '@sportnet/ui/FormField/redux-form';
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { renderSectionTreeOptionLabel } from '../../../../../components/SectionSelect/index';
import { filterSectionTreeOptions } from '../../../../../components/SectionSelect/utils';
import config from '../../../../../config';
import { RootState } from '../../../../../rootReducer';
import { __ } from '../../../../../utilities';
import { sectionTreeOptionsSelector } from '../../../selectors';
import { usedColorsSelector } from '../../selectors';
import { socialOptions } from '../constants';
import { useAuth } from '@sportnet/auth-react';

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    sectionTreeOptions: sectionTreeOptionsSelector(state),
    usedColors: usedColorsSelector(state),
  };
};

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

const FooterFutbalsfzFields: React.FC<IProps> = ({
  usedColors,
  sectionTreeOptions,
}) => {
  const { ppo: appSpace, accessToken } = useAuth();

  return (
    <>
      <Field
        component={FormField}
        name="settings.footer.config.sectionIdOrUniqId"
        type="theselectsimple"
        label={__('Koreňová sekcia pre menu')}
        options={sectionTreeOptions}
        filterOptions={filterSectionTreeOptions}
        renderLabel={renderSectionTreeOptionLabel}
      />
      <Field
        component={FormField}
        name="settings.footer.config.extraSectionIdOrUniqId"
        type="theselectsimple"
        label={__('Koreňová sekcia pre doplnkové menu')}
        options={sectionTreeOptions}
        filterOptions={filterSectionTreeOptions}
        renderLabel={renderSectionTreeOptionLabel}
      />
      <Field
        component={FormField}
        name="settings.footer.config.textColor"
        type="colorInput"
        label={__('Farba textu')}
        propagateChangesImmediately
        colors={usedColors}
      />
      <Field
        component={FormField}
        name="settings.footer.config.backgroundColor"
        type="colorInput"
        label={__('Farba pozadia')}
        propagateChangesImmediately
        colors={usedColors}
      />
      <Field
        component={FormField}
        name="settings.footer.config.backgroundImage"
        label={__('Obrázok pozadia')}
        type="image"
        auth={{
          appId: config.APP_ID,
          appSpace,
          token: accessToken,
        }}
      />
      <Field
        component={FormField}
        name="settings.footer.config.textColorBottom"
        type="colorInput"
        label={__('Farba textu v doplnkovom menu')}
        propagateChangesImmediately
        colors={usedColors}
      />
      <Field
        component={FormField}
        name="settings.footer.config.backgroundColorBottom"
        type="colorInput"
        label={__('Farba pozadia doplnkového menu')}
        propagateChangesImmediately
        colors={usedColors}
      />
      <Field
        component={FormField}
        name="settings.footer.config.borderColor"
        type="colorInput"
        label={__('Farba orámovania')}
        propagateChangesImmediately
        colors={usedColors}
      />
      <Field
        component={FormField}
        name="settings.footer.config.textContent"
        label={__('Doplnkový text')}
      />
      <Field
        component={FormField}
        name="settings.footer.config.social"
        type="theselectsimple"
        label={__('Sociálne siete')}
        options={socialOptions}
        format={(value = '') => value || 'x'}
        parse={(value: string) => (value === 'x' ? '' : value)}
        clearable={false}
      />
    </>
  );
};

export default connect(mapStateToProps)(FooterFutbalsfzFields);
