import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Link from '@sportnet/ui/Link';
import NotFound from '@sportnet/ui/NotFound';
import Segment from '@sportnet/ui/Segment';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { hasCommitFailed, isCommiting } from '@sportnet/redux-list';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import { ILayout } from '../../../library/Pages';
import { RootState } from '../../../rootReducer';
import { __ } from '../../../utilities';
import { initializeOrSetListParams } from '../../App/actions';
import { activeAppspaceIdSelector } from '../../App/selectors';
import { loadLayoutsList } from './actions';
import constants from './constants';
import LayoutsTable from './LayoutsTable';
import { layoutsListSelector } from './selectors';
import { useAuth } from '@sportnet/auth-react';

interface IOwnProps {}

const mapStateToProps = (state: RootState) => ({
  items: layoutsListSelector(state),
  isLoading: isCommiting(constants.LIST_NAME)(state),
  hasCommitFailed: hasCommitFailed(constants.LIST_NAME)(state),
  appSpaceId: activeAppspaceIdSelector(state),
});

const mapDispatchToProps = {
  loadLayoutsList,
  initializeOrSetListParams: thunkToAction(initializeOrSetListParams.action),
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps &
  IOwnProps;

const Layouts: React.FC<Props> = ({
  items,
  loadLayoutsList,
  isLoading,
  hasCommitFailed,
  initializeOrSetListParams,
  history,
  appSpaceId,
}) => {
  const { ppo: appSpace } = useAuth();

  React.useEffect(() => {
    if (!appSpace) {
      return;
    }
    initializeOrSetListParams({
      listName: constants.LIST_NAME,
      params: {
        offset: 0,
      },
    }).then(() => loadLayoutsList({ appSpace }));
  }, [initializeOrSetListParams, loadLayoutsList, appSpace]);

  const shouldShowLoader = !!isLoading && items.length === 0;

  const handleClickItem = React.useCallback(
    (item: ILayout) => {
      history.push(`/admin/${appSpaceId}/editor/layouts/detail/${item._id}`);
    },
    [history, appSpaceId],
  );

  return (
    <ScrollLayout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__('Šablóny')}</HeaderBar.Header>
        </HeaderBar>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarSpacer />
          <ContextBarItem>
            <Link to={`/admin/${appSpaceId}/editor/layouts/detail`}>
              <Button primary>{__('Nová šablóna')}</Button>
            </Link>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <Segment noBottomGutter>
        <Segment noBottomGutter raised loading={shouldShowLoader}>
          {hasCommitFailed ? (
            <NotFound
              title={__('Ups! Nastala chyba pri načítaní')}
              icon="error"
              actionLabel={__('Skúsiť znova')}
              onClickAction={() => appSpace && loadLayoutsList({ appSpace })}
            />
          ) : !isLoading && items.length === 0 ? (
            <NotFound
              icon="file"
              title={__('Žiadne šablóny')}
              actionLabel={__('Vytvoriť šablónu')}
              onClickAction={() => {
                history.push(`/admin/${appSpaceId}/editor/layouts/detail`);
              }}
            />
          ) : (
            <LayoutsTable items={items} onClickItem={handleClickItem} />
          )}
        </Segment>
      </Segment>
    </ScrollLayout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Layouts);
