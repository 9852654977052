import SubmitButton from '@sportnet/ui/Button/Submit';
import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import Segment from '@sportnet/ui/Segment';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { renderSectionTreeOptionLabel } from '../../../components/SectionSelect/index';
import { filterSectionTreeOptions } from '../../../components/SectionSelect/utils';
import { ISettingsBaseHomepageSectionIdOrUniqId } from '../../../library/Pages';
import { RootState } from '../../../rootReducer';
import { __ } from '../../../utilities';
import { sectionTreeOptionsSelector } from '../../Editor/selectors';

export const FORM_NAME = 'homepage-form';

type IOwnProps = {
  hasSubmitFailed: boolean;
};

export interface IFormData {
  homepageSectionIdOrUniqId?: ISettingsBaseHomepageSectionIdOrUniqId;
}

const mapStateToProps = (state: RootState) => ({
  sectionTreeOptions: sectionTreeOptionsSelector(state),
});

type IProps = IOwnProps &
  InjectedFormProps &
  ReturnType<typeof mapStateToProps>;

const HomepageForm: React.FC<IProps> = ({
  submitting,
  handleSubmit,
  sectionTreeOptions,
  hasSubmitFailed,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={12} s={6} l={4}>
          <Segment paddingSize="l" raised>
            <Field
              component={FormField}
              name="homepageSectionIdOrUniqId"
              type="theselectsimple"
              label={__('Sekcia pre hlavnú stránku')}
              options={sectionTreeOptions}
              filterOptions={filterSectionTreeOptions}
              renderLabel={renderSectionTreeOptionLabel}
            />
          </Segment>
        </Col>
      </Row>

      <SubmitButton
        successText={__('Uložené!')}
        isSubmitting={submitting}
        isError={hasSubmitFailed}
      >
        {__('Uložiť zmeny')}
      </SubmitButton>
    </form>
  );
};

export default compose(
  reduxForm({
    form: FORM_NAME,
  }),
  connect(mapStateToProps),
)(HomepageForm) as any;
