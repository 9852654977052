import SubmitButton from '@sportnet/ui/Button/Submit';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import FormField from '@sportnet/ui/FormField';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import Sidebar from '@sportnet/ui/Sidebar';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { isCommiting } from '@sportnet/redux-list';
import removeAccents from '@sportnet/utilities/removeAccents';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import {
  IAdminEditSettings,
  ISettingsBaseSectionLayoutMap,
} from '../../../library/Pages';
import { RootState } from '../../../rootReducer';
import { __ } from '../../../utilities';
import { initializeOrSetListParams } from '../../App/actions';
import { saveSettings } from '../actions';
import { loadLayoutsList } from '../Layouts/actions';
import constants from '../Layouts/constants';
import { layoutsListSelector } from '../Layouts/selectors';
import {
  sectionTreeIsFetchingSelector,
  sectionTreeSelector,
  settingsIsFetchingSelector,
  settingsSelector,
} from '../selectors';
import SectionFilter from './SectionFilter';
import SectionTable from './SectionTable';
import { layoutOptionsSelector } from './selectors';
import useSectionEntries, {
  ISectionEntry,
  ISectionLayoutMap,
} from './useSectionEntries';
import { useAuth } from '@sportnet/auth-react';

interface IOwnProps {}

const mapStateToProps = (state: RootState) => ({
  settings: settingsSelector(state),
  settingsIsFetching: settingsIsFetchingSelector(state),
  sectionTreeIsFetching: sectionTreeIsFetchingSelector(state),
  sectionTree: sectionTreeSelector(state),
  isLoadingLayouts: isCommiting(constants.LIST_NAME)(state),
  layouts: layoutsListSelector(state),
  layoutOptions: layoutOptionsSelector(state),
});

const mapDispatchToProps = {
  loadLayoutsList,
  initializeOrSetListParams: thunkToAction(initializeOrSetListParams.action),
  saveSettings: thunkToAction(saveSettings.action),
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps &
  IOwnProps;

const SectionLayouts: React.FC<Props> = ({
  settingsIsFetching,
  settings,
  sectionTreeIsFetching,
  sectionTree,
  loadLayoutsList,
  isLoadingLayouts,
  layouts,
  initializeOrSetListParams,
  layoutOptions,
  saveSettings,
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [hasSubmitFailed, setHasSubmitFailed] = React.useState(false);
  const [query, setQuery] = React.useState({ q: '' });

  const { ppo: appSpace } = useAuth();

  const [sidebarData, setSidebarData] = React.useState<null | ISectionEntry>(
    null,
  );
  const [sectionLayoutMap, setSectionLayoutMap] =
    React.useState<ISectionLayoutMap>({});

  // load layouts
  React.useEffect(() => {
    if (appSpace) {
      initializeOrSetListParams({
        listName: constants.LIST_NAME,
        params: {
          offset: 0,
        },
      }).then(() => loadLayoutsList({ appSpace }));
    }
  }, [initializeOrSetListParams, loadLayoutsList, appSpace]);

  // init map
  React.useEffect(() => {
    if (settings) {
      const map = (settings.sectionLayoutMap || []).reduce(
        (acc: ISectionLayoutMap, entry) => {
          acc[entry.sectionId] = entry.layoutId;
          return acc;
        },
        {},
      );
      setSectionLayoutMap(map);
    }
  }, [settings]);

  // create items
  const unfilteredItems = useSectionEntries(
    layouts,
    sectionLayoutMap,
    sectionTree,
  );

  // filter items
  const items = React.useMemo(() => {
    const q = removeAccents(query.q);
    return unfilteredItems.filter(item => {
      return removeAccents(item.name).includes(q);
    });
  }, [unfilteredItems, query]);

  const handleClickItem = React.useCallback((item: ISectionEntry) => {
    setSidebarData(item);
  }, []);

  async function handleSubmit() {
    if (appSpace) {
      try {
        setIsSubmitting(true);
        setHasSubmitFailed(false);
        const valuesToSave: IAdminEditSettings = {
          sectionLayoutMap: Object.entries(sectionLayoutMap).reduce(
            (acc: ISettingsBaseSectionLayoutMap, [sectionId, layoutId]) => {
              if (sectionId && layoutId) {
                acc.push({ sectionId: Number(sectionId), layoutId });
              }
              return acc;
            },
            [],
          ),
        };
        await saveSettings({
          appSpace,
          settings: valuesToSave,
        });
        setHasSubmitFailed(false);
      } catch (e) {
        setHasSubmitFailed(true);
      } finally {
        setIsSubmitting(false);
      }
    }
  }

  const shouldShowLoader =
    (settingsIsFetching && !settings) ||
    (sectionTreeIsFetching && !sectionTree.length) ||
    (!!isLoadingLayouts && !layouts.length);

  return (
    <ScrollLayout
      topFixed={
        <>
          <HeaderBar>
            <HeaderBar.Header>
              {__('Priradenie šablón sekciám')}
            </HeaderBar.Header>
          </HeaderBar>
          <SectionFilter query={query} setQuery={setQuery} />
        </>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarSpacer />
          <ContextBarItem>
            <SubmitButton
              disabled={shouldShowLoader}
              isError={hasSubmitFailed}
              isSubmitting={isSubmitting}
              successText={__('Uložené!')}
              onClick={handleSubmit}
            >
              {__('Uložiť')}
            </SubmitButton>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <Segment noBottomGutter>
        <Segment noBottomGutter raised loading={shouldShowLoader}>
          <SectionTable items={items} onClickItem={handleClickItem} />
        </Segment>
      </Segment>
      {sidebarData && (
        <Sidebar
          visible
          onClose={() => setSidebarData(null)}
          header={`${__('Sekcia')}: ${sidebarData.name}`}
        >
          <Segment noBottomGutter>
            <FormField
              label={__('Šablóna sekcie')}
              type="theselectsimple"
              value={sectionLayoutMap[sidebarData._id]}
              onChange={(layoutId: string) =>
                setSectionLayoutMap(prev => ({
                  ...prev,
                  [sidebarData._id]: layoutId,
                }))
              }
              clearable
              options={layoutOptions}
            />
          </Segment>
        </Sidebar>
      )}
    </ScrollLayout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionLayouts);
