import React from 'react';
import AccordionItem from './AccordionItem';

type IOwnProps = {
  opened: string | null;
  onChange: (next: string | null) => void;
  children?: React.ReactNode;
};

type IProps = IOwnProps;

const Accordion: React.FC<IProps> = ({ opened, onChange, children }) => {
  return (
    <>
      {React.Children.map(children, (child: any) => {
        if (child && child.type === AccordionItem) {
          return React.cloneElement(child, {
            active: opened === child.props.id,
            onClick: () =>
              onChange(opened === child.props.id ? null : child.props.id),
          });
        }
        return null;
      })}
    </>
  );
};

export default Accordion;
