import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { initialize } from 'redux-form';
import { getProp } from '@sportnet/utilities';
import ContentLoader from '../../../components/ContentLoader';
import withPopups, { WithPopupsProps } from '../../../components/WithPopups';
import { IAdminEditSettings } from '../../../library/Pages';
import { RootState } from '../../../rootReducer';
import { IThemeProps } from '../../../theme/styled-components';
import { __ } from '../../../utilities';
import { activeAppspaceIdSelector } from '../../App/selectors';
import {
  deleteSettingsSegment,
  loadSectionTree,
  loadSettings,
  saveSettings,
} from '../../Editor/actions';
import {
  settingsIsFetchingSelector,
  settingsSelector,
} from '../../Editor/selectors';
import Form, { FORM_NAME, IFormData } from './form';
import { useAuth } from '@sportnet/auth-react';

type IOwnProps = {};

const mapStateToProps = (state: RootState) => ({
  activeAppspaceId: activeAppspaceIdSelector(state),
  settings: settingsSelector(state),
  settingsIsFetching: settingsIsFetchingSelector(state),
});

const mapDispatchToProps = {
  saveSettings: saveSettings.action,
  loadSettings: loadSettings.action,
  deleteSettingsSegment: deleteSettingsSegment.action,
  initialize,
  loadSectionTree: loadSectionTree.action,
};

type IProps = IOwnProps &
  WithPopupsProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  IThemeProps;

const Homepage: React.FC<IProps> = ({
  saveSettings,
  loadSettings,
  deleteSettingsSegment,
  initialize,
  settings,
  alert,
  settingsIsFetching,
  loadSectionTree,
}) => {
  const [hasSubmitFailed, setHasSubmitFailed] = React.useState(false);

  const { ppo: appSpace } = useAuth();

  React.useEffect(() => {
    if (!appSpace) {
      return;
    }
    loadSettings({ appSpace });
  }, [loadSettings, appSpace]);

  React.useEffect(() => {
    if (!appSpace) {
      return;
    }
    loadSectionTree({ appSpace });
  }, [loadSectionTree, appSpace]);

  React.useEffect(() => {
    initialize(FORM_NAME, {
      homepageSectionIdOrUniqId: getProp(settings, [
        'homepageSectionIdOrUniqId',
      ]),
    });
  }, [settings, initialize]);

  async function handleSubmit(values: IFormData) {
    if (appSpace) {
      try {
        const homepageSectionIdOrUniqId = getProp(
          values,
          ['homepageSectionIdOrUniqId'],
          null,
        );

        const valuesToSave: IAdminEditSettings = {};

        if (homepageSectionIdOrUniqId) {
          valuesToSave.homepageSectionIdOrUniqId = String(
            homepageSectionIdOrUniqId,
          );
        } else {
          await deleteSettingsSegment({
            appSpace,
            settingsSegment: 'homepageSectionIdOrUniqId',
          });
        }

        if (Object.keys(valuesToSave).length > 0) {
          await saveSettings({
            appSpace,
            settings: valuesToSave,
          });
        }
        setHasSubmitFailed(false);
      } catch (e) {
        setHasSubmitFailed(true);
        alert(__('Nastavenia nie je možné uložiť'));
      }
    }
  }

  return (
    <ScrollLayout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__('Hlavná stránka')}</HeaderBar.Header>
        </HeaderBar>
      }
    >
      {settingsIsFetching && !settings ? (
        <ContentLoader />
      ) : (
        <Segment noBottomGutter>
          <Form onSubmit={handleSubmit} hasSubmitFailed={hasSubmitFailed} />
        </Segment>
      )}
    </ScrollLayout>
  );
};

export default compose(
  withPopups,
  connect(mapStateToProps, mapDispatchToProps),
)(Homepage);
