import BasicTable from '@sportnet/ui/BasicTable';
import React from 'react';
import Tag from '../../../components/Tag';
import { ILayout } from '../../../library/Pages';
import { IThemeProps, withTheme } from '../../../theme/styled-components';
import { __ } from '../../../utilities';

interface OwnProps {
  items: ILayout[];
  onClickItem: (item: ILayout) => void;
}

type Props = OwnProps & IThemeProps;

const LayoutsTable: React.FC<Props> = ({ items, onClickItem, theme }) => {
  return (
    <BasicTable
      columns={[
        {
          id: 'name',
          header: __('Názov'),
        },
      ]}
      rows={items}
      onClickRow={onClickItem}
      renderRow={(item: ILayout) => [
        <>
          {item.name}
          {item.default && (
            <>
              &nbsp; &nbsp;
              <Tag color={theme.color.primary}>{__('predvolená')}</Tag>
            </>
          )}
        </>,
      ]}
      rowKey="_id"
    />
  );
};

export default withTheme(LayoutsTable);
