import HeaderBar from '@sportnet/ui/HeaderBar';
import Icon from '@sportnet/ui/Icon';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import Sidebar from '@sportnet/ui/Sidebar';
import Text from '@sportnet/ui/Text';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { initialize } from 'redux-form';
import { getProp } from '@sportnet/utilities';
import ContactFormPreview from '../../../assets/contact-form-preview.gif';
import ContentLoader from '../../../components/ContentLoader';
import withPopups, { WithPopupsProps } from '../../../components/WithPopups';
import { RootState } from '../../../rootReducer';
import styled, {
  IThemeProps,
  withTheme,
} from '../../../theme/styled-components';
import { __ } from '../../../utilities';
import { activeAppspaceIdSelector } from '../../App/selectors';
import {
  deleteSettingsSegment,
  loadSettings,
  saveSettings,
} from '../../Editor/actions';
import {
  settingsIsFetchingSelector,
  settingsSelector,
} from '../../Editor/selectors';
import Form, { FORM_NAME, IFormData } from './form';
import { useAuth } from '@sportnet/auth-react';

const ContactFormPreviewImage = styled.img`
  width: 100%;
  border: 1px solid #ccc;
  margin-bottom: ${rem(24)};
`;

const InfoMessageWrapper = styled.div`
  display: flex;
  align-items: start;
`;

const MessageText = styled(Text)`
  font-style: italic;
  margin-left: ${({ theme }) => rem(theme.grid.gutterWidth)};
  font-size: ${rem(14)};
`;

type IOwnProps = {};

const mapStateToProps = (state: RootState) => ({
  activeAppspaceId: activeAppspaceIdSelector(state),

  settings: settingsSelector(state),
  settingsIsFetching: settingsIsFetchingSelector(state),
});

const mapDispatchToProps = {
  saveSettings: saveSettings.action,
  loadSettings: loadSettings.action,
  deleteSettingsSegment: deleteSettingsSegment.action,
  initialize,
};

type IProps = IOwnProps &
  WithPopupsProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  IThemeProps;

const C: React.FC<IProps> = ({
  saveSettings,
  loadSettings,
  deleteSettingsSegment,
  initialize,
  settings,
  alert,
  theme,
  settingsIsFetching,
}) => {
  const [hasSubmitFailed, setHasSubmitFailed] = React.useState(false);
  const [infoSidebarOpened, setInfoSidebarOpened] = React.useState(false);

  const { ppo: appSpace } = useAuth();

  React.useEffect(() => {
    if (!appSpace) {
      return;
    }
    loadSettings({ appSpace });
  }, [loadSettings, appSpace]);

  React.useEffect(() => {
    const formValues = getProp(settings, ['contactForm'], {});
    initialize(FORM_NAME, {
      enabled: Object.keys(formValues).length > 0,
      ...formValues,
    });
  }, [settings, initialize]);

  async function handleSubmit(values: IFormData) {
    if (appSpace) {
      try {
        setHasSubmitFailed(false);

        const valuesToSave: IFormData = { ...values };

        if (valuesToSave.enabled) {
          delete valuesToSave.enabled;
          await saveSettings({
            appSpace,
            settings: {
              contactForm: valuesToSave,
            },
          });
        } else {
          await deleteSettingsSegment({
            appSpace,
            settingsSegment: 'contactForm',
          });
        }

        setHasSubmitFailed(false);
      } catch (e) {
        await alert(__('Kontaktný formulár nie je možné uložiť'));
        setHasSubmitFailed(true);
      }
    }
  }

  return (
    <ScrollLayout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__('Kontaktný formulár')}</HeaderBar.Header>
          <HeaderBar.Spacer />
          <HeaderBar.Action
            icon="question"
            onClick={() => setInfoSidebarOpened(o => !o)}
          >
            {!infoSidebarOpened && __('Nápoveda')}
          </HeaderBar.Action>
        </HeaderBar>
      }
    >
      {settingsIsFetching && !settings ? (
        <ContentLoader />
      ) : (
        <Segment noBottomGutter>
          <Form onSubmit={handleSubmit} hasSubmitFailed={hasSubmitFailed} />
        </Segment>
      )}
      <Sidebar
        header={__('Nápoveda')}
        visible={infoSidebarOpened}
        onClose={() => {
          setInfoSidebarOpened(false);
        }}
      >
        <Segment>
          <ContactFormPreviewImage
            alt={__('Ukážka kontaktného formulára')}
            src={ContactFormPreview}
          />
          <InfoMessageWrapper>
            <Icon name="tooltip" color={theme.color.fadedText} size="l" />
            <MessageText faded>
              {__(
                'Povolením kontaktného formulára sa na vašej stránke zobrazí v pravom dolnom rohu ikona "Obálky". Po kliknutí na túto ikonu sa zobrazí kontaktný formulár.',
              )}
            </MessageText>
          </InfoMessageWrapper>
        </Segment>
      </Sidebar>
    </ScrollLayout>
  );
};

export default compose(
  withPopups,
  connect(mapStateToProps, mapDispatchToProps),
  withTheme,
)(C as any);
