import { createSelector } from 'reselect';
import { getProp } from '@sportnet/utilities';
import { RootState } from '../../rootReducer';

const applicationDomainSelector = (state: RootState) => {
  return state.application;
};

export const entitiesSelector = (state: RootState) => {
  return state.entities;
};

export const activeAppspaceIdSelector = createSelector(
  applicationDomainSelector,
  substate => substate.activeAppspace,
);

export const tokenSelector = createSelector(
  applicationDomainSelector,
  substate => substate.token,
);

export const appspacesSelector = createSelector(
  applicationDomainSelector,
  substate => substate.appspaces,
);

export const appsSelector = createSelector(
  applicationDomainSelector,
  substate => substate.apps,
);

export const activeAppspaceSelector = createSelector(
  appspacesSelector,
  activeAppspaceIdSelector,
  (appspaces, activeId) => {
    if (activeId && appspaces) {
      const ppo = appspaces.find(appspace => {
        return appspace.app_space === activeId;
      });
      return ppo || null;
    }
    return null;
  },
);

export const activeAppspaceNameSelector = createSelector(
  activeAppspaceSelector,
  substate => {
    if (substate) {
      return getProp(substate, ['org_profile', 'name'], null);
    }
    return null;
  },
);

export const activeAppspaceProfileSelector = createSelector(
  activeAppspaceSelector,
  substate => {
    if (substate) {
      return getProp(substate, ['org_profile'], null);
    }
    return null;
  },
);

export const authUserSelector = createSelector(
  applicationDomainSelector,
  substate => substate.authUser,
);

export const applicationInfoSelector = createSelector(
  applicationDomainSelector,
  substate => substate.applicationInfo,
);
