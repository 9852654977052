import * as React from 'react';
import {
  Field,
  FieldArray,
  WrappedFieldArrayProps,
  WrappedFieldProps,
} from 'redux-form';
import { __ } from '../../../../../utilities';
import Button from '@sportnet/ui/Button';
import Segment from '@sportnet/ui/Segment';
import styled from 'styled-components';
import { rem } from 'polished';
import EditModal, { EditMetatag } from './EditModal';

const MetatagsListWrapper = styled.div``;

const MetatagWrapper = styled.div`
  cursor: pointer;
  :hover {
    font-weight: 700;
  }
`;

const DeleteButton = styled(Button)`
  position: absolute;
  right: ${rem(5)};
  top: ${rem(5)};
  padding: ${rem(0)};
  min-height: 0;
  margin: 0 0 auto auto;
  border: none;
  svg {
    width: ${rem(18)};
    height: ${rem(18)};
  }
`;

const INITIAL_FORM_VALUES = {
  content: '',
  name: '',
  property: '',
};

/**
 * Renders metatag items.
 */
const renderField = ({ input }: WrappedFieldProps) => {
  return (
    <div>
      <code>
        {`<meta${!!input.value.name ? ` name="${input.value.name}"` : ''}${
          !!input.value.property ? ` property="${input.value.property}"` : ''
        } content="${input.value.content}"`}
      </code>
    </div>
  );
};

/**
 * Renders items of FieldArray that contains metatags.
 */
const renderMetatags = ({
  fields,
  metatag,
  onChangeMetatag,
}: WrappedFieldArrayProps & {
  metatag: EditMetatag | null;
  onChangeMetatag: (values: EditMetatag | null) => void;
}) => {
  const handleAddNewMetatag = () => {
    onChangeMetatag(INITIAL_FORM_VALUES);
  };

  const handleSaveMetatag = (values: EditMetatag) => {
    const { idx, ...restValues } = values;
    if (typeof idx !== 'undefined') {
      fields.remove(idx);
      fields.insert(idx, restValues);
    } else {
      fields.push(restValues);
    }
    onChangeMetatag(null);
  };

  const handleDeleteMetatag = (idx: number) => {
    fields.remove(idx);
  };

  const handleCloseModal = () => {
    onChangeMetatag(null);
  };

  const handleChangeMetatag = (idx: number) => {
    const values = fields.get(idx);
    onChangeMetatag({ ...values, idx });
  };

  return (
    <div>
      <EditModal
        metatag={metatag}
        onSave={handleSaveMetatag}
        onClose={handleCloseModal}
      />
      <MetatagsListWrapper>
        {fields.map((metatag, idx) => (
          <Segment raised key={metatag}>
            <DeleteButton
              danger
              basic
              icon="close"
              type="button"
              onClick={() => {
                handleDeleteMetatag(idx);
              }}
            ></DeleteButton>
            <MetatagWrapper
              onClick={() => {
                handleChangeMetatag(idx);
              }}
            >
              <Field name={metatag} type="text" component={renderField} />
            </MetatagWrapper>
          </Segment>
        ))}
      </MetatagsListWrapper>
      <Button primary block onClick={handleAddNewMetatag}>
        {__('Pridať')}
      </Button>
    </div>
  );
};

const MetatagsFields: React.FC = () => {
  // selected metatag - new, or existing one.
  const [metatag, setMetatag] = React.useState<EditMetatag | null>(null);

  const handleCahngeMetatag = (values: EditMetatag | null) => {
    setMetatag(values);
  };

  return (
    <FieldArray
      name="settings.metatags"
      component={renderMetatags}
      metatag={metatag}
      onChangeMetatag={handleCahngeMetatag}
    />
  );
};

export default MetatagsFields;
