import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import React from 'react';
import styled from '../../theme/styled-components';

const SSegment = styled(Segment)`
  padding-right: 0;
  padding-left: 0;
`;

type IOwnProps = {
  id: string;
  title: string;
  active?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
};

type IProps = IOwnProps;

const AccordionItem: React.FC<IProps> = ({
  title,
  active,
  onClick,
  children,
}) => {
  return (
    <SSegment
      noBottomGutter
      header={
        <SegmentHeader
          collapsible
          isCollapsed={!active}
          toggleCollapse={() => {
            if (onClick) {
              onClick();
            }
          }}
          size="xs"
        >
          {title}
        </SegmentHeader>
      }
    >
      {children}
    </SSegment>
  );
};

export default AccordionItem;
