import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { INormalizedSectionNode } from '../../library/App';
import { IAdminSettings } from '../../library/Pages';
import * as actions from './actions';

type SettingsState = Readonly<{
  isFetching: boolean;
  settings: IAdminSettings | null;
}>;

const SETTINGS_INITIAL_STATE: SettingsState = {
  isFetching: false,
  settings: null,
};

export const settingsReducer = reducerWithInitialState(SETTINGS_INITIAL_STATE)
  .case(actions.loadSettings.async.started, state => ({
    ...state,
    isFetching: true,
  }))
  .case(actions.loadSettings.async.done, (state, { result }) => ({
    ...state,
    settings: result,
    isFetching: false,
  }))
  .case(actions.saveSettings.async.done, (state, { result }) => ({
    ...state,
    settings: result,
  }))
  .case(actions.deleteSettingsSegment.async.done, (state, { result }) => ({
    ...state,
    settings: result,
  }))
  .case(actions.loadSettings.async.failed, state => ({
    ...state,
    isFetching: false,
  }));

type SectionTreeState = Readonly<{
  isFetching: boolean;
  tree: INormalizedSectionNode[];
}>;

const SECTION_TREE_INITIAL_STATE: SectionTreeState = {
  isFetching: false,
  tree: [],
};

export const sectionTreeReducer = reducerWithInitialState(
  SECTION_TREE_INITIAL_STATE,
)
  .case(actions.loadSectionTree.async.started, state => ({
    ...state,
    isFetching: true,
  }))
  .case(actions.loadSectionTree.async.done, (state, { result }) => ({
    ...state,
    tree: result,
    isFetching: false,
  }))
  .case(actions.loadSectionTree.async.failed, state => ({
    ...state,
    isFetching: false,
  }));
