import Button from '@sportnet/ui/Button';
import SubmitButton from '@sportnet/ui/Button/Submit';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import NotFound from '@sportnet/ui/NotFound';
import Segment from '@sportnet/ui/Segment';
import Sidebar from '@sportnet/ui/Sidebar';
import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import {
  hasSubmitFailed,
  initialize,
  isDirty,
  isSubmitting,
  SubmissionError,
  submit,
} from 'redux-form';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import ContentLoader from '../../../components/ContentLoader';
import withPopups, { WithPopupsProps } from '../../../components/WithPopups';
import { ILayout } from '../../../library/Pages';
import PagesApi from '../../../PagesApi';
import { RootState } from '../../../rootReducer';
import styled from '../../../theme/styled-components';
import { __ } from '../../../utilities';
import { deleteLayout, loadDetail, saveLayout } from './actions';
import LayoutDemo from './LayoutDemo';
import DetailForm, { IFormData } from './LayoutForm';
import { FORM_NAME } from './LayoutForm/constants';
import { detailErrorSelector, detailSelector } from './selectors';
import { useAuth } from '@sportnet/auth-react';

const SScrollLayout = styled(ScrollLayout)`
  & > div:nth-child(2) {
    display: flex;
    > div {
      flex: 1;
    }
  }
`;

type IOwnProps = {};

type RouteProps = RouteComponentProps<{ layoutId?: string }>;

const mapStateToProps = (state: RootState, props: RouteProps) => {
  const { layoutId = '' } = props.match.params;
  return {
    detail: detailSelector(layoutId)(state),
    error: detailErrorSelector(layoutId)(state),
    isDirty: isDirty(FORM_NAME)(state) || false,
    submitting: isSubmitting(FORM_NAME)(state) || false,
    submitFailed: hasSubmitFailed(FORM_NAME)(state) || false,
  };
};

const mapDispatchToProps = {
  loadDetail: thunkToAction(loadDetail.action),
  initialize,
  saveLayout: thunkToAction(saveLayout.action),
  deleteLayout: thunkToAction(deleteLayout.action),
  submitForm: () => submit(FORM_NAME),
};

type IProps = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps &
  WithPopupsProps;

const Detail: React.FC<IProps> = ({
  match: {
    params: { layoutId },
  },
  error,
  alert,
  confirm,
  detail,
  loadDetail,
  initialize,
  saveLayout,
  deleteLayout,
  isDirty,
  submitting,
  submitFailed,
  submitForm,
  history,
}) => {
  const [sidebarOpened, setSidebarOpened] = React.useState(true);
  const [defaultDetail, setDefaultDetail] = React.useState<ILayout | null>(
    null,
  );
  const [isDeleting, setIsDeleting] = React.useState(false);

  const { ppo: appSpace } = useAuth();

  // load detail
  React.useEffect(() => {
    if (!appSpace) {
      return;
    }
    (async () => {
      if (layoutId) {
        await loadDetail({ appSpace, layoutId });
      } else {
        try {
          const res = await PagesApi.publicGetSettingsSegment(
            appSpace,
            'layouts',
          );
          const defaultLayout =
            (res.layouts || []).find(l => l.default) || null;
          setDefaultDetail(defaultLayout);
        } catch (e) {
          console.error('Default layout cannot be retrieved');
        }
      }
    })();
  }, [loadDetail, layoutId, appSpace]);

  // init form
  React.useEffect(() => {
    if (detail) {
      initialize(FORM_NAME, {
        default: detail.default || false,
        name: detail.name,
        settings: detail.settings,
      } as IFormData);
    } else if (defaultDetail) {
      initialize(FORM_NAME, {
        default: false,
        name: '',
        settings: defaultDetail.settings,
      } as IFormData);
    }
  }, [detail, initialize, defaultDetail]);

  async function handleSubmit(values: IFormData) {
    if (appSpace) {
      try {
        const savedLayout = await saveLayout({ appSpace, layoutId, values });
        if (!layoutId) {
          history.push(
            `/admin/${appSpace}/editor/layouts/detail/${savedLayout._id}`,
          );
        }
      } catch (e) {
        const submissionError = {
          _error: __('Šablónu sa nepodarilo uložiť'),
        };
        alert(submissionError._error);
        throw new SubmissionError(submissionError);
      }
    }
  }

  async function handleDelete() {
    if (!layoutId || !appSpace) {
      return;
    }
    if (await confirm(__('Naozaj chcete odstrániť túto šablónu?'))) {
      try {
        setIsDeleting(true);
        await deleteLayout({ appSpace, layoutId });
        history.push(`/admin/${appSpace}/editor/layouts`);
      } catch (e) {
        setIsDeleting(false);
        await alert(__('Šablónu nie je možné odstrániť'));
      }
    }
  }

  return (
    <SScrollLayout
      topFixed={
        <HeaderBar>
          <HeaderBar.Action
            icon="back"
            as={Link}
            to={`/admin/${appSpace}/editor/layouts`}
            title={__('Späť na zoznam')}
          />
          <HeaderBar.Header>
            {layoutId ? __('Detail šablóny') : __('Nová šablóna')}
          </HeaderBar.Header>
          <HeaderBar.Spacer />
          <HeaderBar.Action
            icon="settings"
            onClick={() => setSidebarOpened(o => !o)}
          >
            {!sidebarOpened && __('Nastavenia')}
          </HeaderBar.Action>
        </HeaderBar>
      }
      bottomFixed={
        <ContextBar>
          {detail && (
            <ContextBarItem>
              <Button
                danger
                basic
                disabled={!!error}
                onClick={handleDelete}
                loading={isDeleting}
              >
                {__('Odstrániť')}
              </Button>
            </ContextBarItem>
          )}
          <ContextBarSpacer />
          <ContextBarItem>
            <SubmitButton
              successText={__('Uložené!')}
              isSubmitting={submitting}
              isError={submitFailed}
              disabled={!isDirty || !!error}
              onClick={submitForm}
            >
              {__('Uložiť')}
            </SubmitButton>
          </ContextBarItem>
        </ContextBar>
      }
    >
      {!!error ? (
        <Segment noBottomGutter>
          <NotFound
            title={__('Detail šablóny nie je možné načítať.')}
            icon="error"
            actionLabel={__('Späť na zoznam')}
            onClickAction={() => {
              history.push(`/admin/${appSpace}/editor/layouts`);
            }}
          />
        </Segment>
      ) : !detail && !defaultDetail ? (
        <Segment noBottomGutter>
          <ContentLoader />
        </Segment>
      ) : (
        <>
          <LayoutDemo />
          <Sidebar
            visible={sidebarOpened}
            onClose={() => setSidebarOpened(false)}
            header={__('Nastavenia šablóny')}
          >
            <DetailForm onSubmit={handleSubmit} />
          </Sidebar>
        </>
      )}
    </SScrollLayout>
  );
};

export default compose(
  withPopups,
  connect(mapStateToProps, mapDispatchToProps),
)(Detail);
