import FormField from '@sportnet/ui/FormField/redux-form';
import * as React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { renderSectionTreeOptionLabel } from '../../../../../components/SectionSelect';
import { filterSectionTreeOptions } from '../../../../../components/SectionSelect/utils';
import config from '../../../../../config';
import { RootState } from '../../../../../rootReducer';
import { __ } from '../../../../../utilities';
import {
  activeAppspaceIdSelector,
  tokenSelector,
} from '../../../../App/selectors';
import { sectionTreeOptionsSelector } from '../../../selectors';
import { usedColorsSelector } from '../../selectors';
import { socialOptions } from '../constants';

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    sectionTreeOptions: sectionTreeOptionsSelector(state),
    usedColors: usedColorsSelector(state),
    appSpace: activeAppspaceIdSelector(state),
    token: tokenSelector(state),
  };
};

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

const HeaderFutbalsfzFields: React.FC<IProps> = ({
  usedColors,
  sectionTreeOptions,
  appSpace,
  token,
}) => {
  return (
    <>
      <Field
        component={FormField}
        name="settings.header.config.logo"
        label={__('Logo')}
        type="image"
        auth={{
          appId: config.APP_ID,
          appSpace,
          token,
        }}
      />
      <Field
        component={FormField}
        name="settings.header.config.logoSmall"
        label={__('Logo malé (nepovinné)')}
        type="image"
        auth={{
          appId: config.APP_ID,
          appSpace,
          token,
        }}
      />
      <Field
        component={FormField}
        name="settings.header.config.logoLinkUrl"
        label={__('Prelinkovanie loga')}
        placeholder="URL"
      />
      <Field
        component={FormField}
        name="settings.header.config.sectionIdOrUniqId"
        type="theselectsimple"
        label={__('Koreňová sekcia pre menu')}
        options={sectionTreeOptions}
        filterOptions={filterSectionTreeOptions}
        renderLabel={renderSectionTreeOptionLabel}
      />
      <Field
        component={FormField}
        name="settings.header.config.textColor"
        type="colorInput"
        label={__('Farba textu')}
        propagateChangesImmediately
        colors={usedColors}
      />
      <Field
        component={FormField}
        name="settings.header.config.backgroundColor"
        type="colorInput"
        label={__('Farba pozadia')}
        propagateChangesImmediately
        colors={usedColors}
      />
      <Field
        component={FormField}
        name="settings.header.config.borderColor"
        type="colorInput"
        label={__('Farba orámovania')}
        propagateChangesImmediately
        colors={usedColors}
      />
      <Field
        component={FormField}
        name="settings.header.config.panelBackgroundColor"
        type="colorInput"
        label={__('Farba pozadia menu')}
        propagateChangesImmediately
        colors={usedColors}
      />
      <Field
        component={FormField}
        name="settings.header.config.panelTextColor"
        type="colorInput"
        label={__('Farba textu v menu')}
        propagateChangesImmediately
        colors={usedColors}
      />
      <Field
        component={FormField}
        name="settings.header.config.floatingPanelBackgroundColor"
        type="colorInput"
        label={__('Farba pozadia plachty')}
        propagateChangesImmediately
        colors={usedColors}
      />
      <Field
        component={FormField}
        name="settings.header.config.social"
        type="theselectsimple"
        label={__('Sociálne siete')}
        options={socialOptions}
        format={(value = '') => value || 'x'}
        parse={(value: string) => (value === 'x' ? '' : value)}
        clearable={false}
      />
    </>
  );
};

export default connect(mapStateToProps)(HeaderFutbalsfzFields);
