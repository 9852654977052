import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import withPopups, { WithPopupsProps } from '../../components/WithPopups';
import { loadSectionTree, loadSettings } from './actions';
import Layout from './Layout';
import Layouts from './Layouts';
import SectionLayouts from './SectionLayouts';
import SiteDemo from './SiteDemo';
import { useAuth } from '@sportnet/auth-react';

type IOwnProps = {};

const mapDispatchToProps = {
  loadSettings: loadSettings.action,
  loadSectionTree: loadSectionTree.action,
};

type IProps = IOwnProps &
  typeof mapDispatchToProps &
  RouteComponentProps &
  WithPopupsProps;

const Editor: React.FC<IProps> = ({ loadSettings, loadSectionTree }) => {
  const { ppo: appSpace } = useAuth();

  React.useEffect(() => {
    if (!appSpace) {
      return;
    }
    loadSettings({ appSpace });
  }, [loadSettings, appSpace]);

  React.useEffect(() => {
    if (!appSpace) {
      return;
    }
    loadSectionTree({ appSpace });
  }, [loadSectionTree, appSpace]);

  return (
    <Switch>
      <Route exact path="/admin/:appspace/editor" component={SiteDemo} />
      <Route exact path="/admin/:appspace/editor/layouts" component={Layouts} />
      <Route
        exact
        path="/admin/:appspace/editor/layouts/detail/:layoutId?"
        component={Layout}
      />
      <Route
        exact
        path="/admin/:appspace/editor/section-layouts"
        component={SectionLayouts}
      />
    </Switch>
  );
};

export default compose(
  withPopups,
  connect(undefined, mapDispatchToProps),
)(Editor);
