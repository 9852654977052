import React from 'react';
import { Article, SectionPublic } from '../CmsApi';
import { SectionId, Writeable } from '../library/App';
import { IAdminSettings } from '../library/Pages';

export const getIdFromProps = (
  props:
    | {
        id: string;
      }
    | {
        params: {
          id: string;
        };
      },
) => {
  if (!('id' in props)) {
    return Number(props.params.id);
  }
  return Number(props.id);
};

export const getArticleUrl = (article: Article) => {
  if (article.redirect) {
    return article.redirect;
  }
  if (article.url) {
    return `/${article.url}`;
  }
  return `/article/${article._id}`;
};

export const getSectionUrl = (section: SectionPublic) => {
  if (section.url) {
    return `/${section.url}`;
  } else {
    return `/section/${section._id}`;
  }
};

export const __ = (text: string) => text;

export function useWhyDidYouUpdate(name: string, props: any) {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = React.useRef<any>();

  React.useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      // Use this object to keep track of changed props
      const changesObj: any = {};
      // Iterate through keys
      allKeys.forEach(key => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });

      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        console.log('[why-did-you-update]', name, changesObj);
      }
    }

    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
}

export function delay(time = 1000) {
  return new Promise<void>(resolve => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}

interface IEntity {
  [key: string]: any;
}

interface IEntities {
  [key: string]: IEntity;
}

export const updateEntities = (
  oldEntities: IEntities,
  entitiesToUpdate: IEntities,
): any => {
  // quick fix (padnuty live)
  const entities = entitiesToUpdate as Writeable<typeof entitiesToUpdate>;
  return Object.keys(entities).reduce(
    (acc: Partial<typeof entities>, entityName: keyof typeof entities) => {
      if (entityName in oldEntities) {
        acc[entityName] = Object.keys(entities[entityName]).reduce(
          (innerAcc: { [key: string]: any }, entityId: string) => {
            if (entityId in oldEntities[entityName]) {
              innerAcc[entityId] = {
                ...oldEntities[entityName][entityId],
                ...entities[entityName][entityId],
              };
            } else {
              innerAcc[entityId] = entities[entityName][entityId];
            }
            return innerAcc;
          },
          {},
        );
      } else {
        acc[entityName] = entities[entityName];
      }
      return acc;
    },
    {},
  );
};

export const required = (value: string): string | null => {
  if (value) {
    return null;
  }
  return __('Pole je povinné');
};

export const isValidColor = (input: string) => {
  return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(input);
};

export const debounce = <A extends any[]>(
  func: (...args: A) => void,
  delay = 1000,
) => {
  let timeout: NodeJS.Timeout;
  return (...args: A) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

export const emptyFn = () => {
  /**/
};

export const normalizeSectionParent = (
  parent: SectionId[] | SectionId | null | undefined,
): SectionId[] => {
  if (parent) {
    if (Array.isArray(parent)) {
      return parent;
    } else {
      return [parent];
    }
  }
  return [];
};

export const getPreviewUrlPattern = (settings: IAdminSettings | null) => {
  if (settings && settings.domain) {
    if (settings.previewUrlPattern) {
      return settings.previewUrlPattern.replace('{{domain}}', settings.domain);
    }
    return `https://${settings.domain}/previews/{{previewId}}`;
  }

  return null;
};

export const getArticleUrlPattern = (settings: IAdminSettings | null) => {
  if (settings && settings.domain) {
    if (settings.articleUrlPattern) {
      return settings.articleUrlPattern.replace('{{domain}}', settings.domain);
    }
    return `https://${settings.domain}/{{uri}}`;
  }

  return null;
};

export const getSectionUrlPattern = (settings: IAdminSettings | null) => {
  if (settings && settings.domain) {
    if (settings.sectionUrlPattern) {
      return settings.sectionUrlPattern.replace('{{domain}}', settings.domain);
    }
    return `https://${settings.domain}/{{uri}}`;
  }

  return null;
};
