import FormField from '@sportnet/ui/FormField';
import React from 'react';

// TODO: Natypovat
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FinalFormField: React.FC<any> = props => {
  const { required, label, meta, input, options, info, disabled } = props;
  return (
    <FormField
      required={required}
      name={input.name}
      value={input.value}
      options={options}
      onChange={input.onChange}
      label={label}
      type={input.type}
      error={meta.error && meta.touched ? meta.error : ''}
      onBlur={input.onBlur}
      info={info}
      disabled={disabled}
    />
  );
};

export default FinalFormField;
