import FormField from '@sportnet/ui/FormField/redux-form';
import * as React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import { RootState } from '../../../../../rootReducer';
import { __ } from '../../../../../utilities';
import FooterFutbalsfzFields from './FooterFutbalsfzFields';
import FooterSimpleFields from './FooterSimpleFields';

const footerOptions = [
  {
    label: __('Žiadna'),
    value: 'x',
  },
  {
    label: __('Jednoduchá'),
    value: 'simple-1',
  },
  {
    label: __('Pätička s plachtou'),
    value: 'futbalsfz',
  },
];

interface IOwnProps {
  formName: string;
}

const mapStateToProps = (state: RootState, { formName }: IOwnProps) => {
  const selector = formValueSelector(formName);
  return {
    footerType: selector(state, 'settings.footer.type'),
  };
};

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

const FooterFields: React.FC<IProps> = ({ footerType }) => {
  let footerFields = null;

  switch (footerType) {
    case 'simple-1':
      footerFields = <FooterSimpleFields />;
      break;
    case 'futbalsfz':
      footerFields = <FooterFutbalsfzFields />;
      break;
    default:
      break;
  }

  return (
    <>
      <Field
        component={FormField}
        name="settings.footer.type"
        type="theselectsimple"
        label={__('Typ')}
        options={footerOptions}
        format={(value = '') => value || 'x'}
        parse={(value: string) => (value === 'x' ? '' : value)}
        clearable={false}
      />
      {footerFields}
    </>
  );
};

export default connect(mapStateToProps)(FooterFields);
