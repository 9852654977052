import { IOptions } from '@sportnet/ui/TheSelect/types';
import { defaultFilterFunction } from '@sportnet/ui/TheSelect/utils';

export const filterSectionTreeOptions = (
  options: IOptions,
  inputValue: string,
) => {
  // filter + include parents
  const shownSectionsById = options.reduce(
    (acc: { [key: string]: boolean }, option) => {
      if (defaultFilterFunction(option, inputValue)) {
        acc[option.value || ''] = true;
        const { parent } = option;
        if (parent) {
          if (Array.isArray(parent)) {
            for (const parentId of parent) {
              acc[parentId] = true;
            }
          } else {
            acc[parent] = true;
          }
        }
      }
      return acc;
    },
    {},
  );
  return options.filter(option => shownSectionsById[option.value || '']);
};
