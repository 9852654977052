import { IAdminSettings } from 'library/Pages';
import {
  getArticleUrlPattern,
  getPreviewUrlPattern,
  getSectionUrlPattern,
} from 'utilities';

const CMS_APP_BASE_URL =
  process.env.REACT_APP_CMS_APP_BASE_URL ?? 'https://cms.sportnet.online';

const getCmsUrl = (
  appId: string,
  appSpace: string,
  contentDivider: string,
  settings: IAdminSettings,
) => {
  const previewUrlPattern = getPreviewUrlPattern(settings);
  const articleUrlPattern = getArticleUrlPattern(settings);
  const sectionUrlPattern = getSectionUrlPattern(settings);
  const articleLayouts = settings?.articleLayouts || [];
  const parentUrl = '';
  const settingsParamValue = btoa(
    JSON.stringify({
      parentUrl,
      previewUrlPattern,
      articleUrlPattern,
      sectionUrlPattern,
      articleLayouts,
    }),
  );
  const searchParams = new URLSearchParams();
  searchParams.set('settings', settingsParamValue);

  return `${CMS_APP_BASE_URL}/app/${appId}/${appSpace}/${contentDivider}?${searchParams.toString()}`;
};

export default getCmsUrl;
