import React from 'react';
import { Settings_Base_metatag } from '../../../../../PagesApi';
import Modal, { ModalContent, ModalActions } from '@sportnet/ui/Modal';
import Button from '@sportnet/ui/Button';
import { required, __ } from '../../../../../utilities';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import FinalFormField from '../../../../../components/FinalFormField';
import Message from '@sportnet/ui/Message';

export type EditMetatag = Settings_Base_metatag & { idx?: number };

interface OwnProps {
  metatag: EditMetatag | null;
  onClose: () => void;
  onSave: (values: Settings_Base_metatag) => void;
}

const EditModal: React.FC<OwnProps> = ({ metatag, onSave, onClose }) => {
  const handleFormSubmit = (values: EditMetatag) => {
    try {
      const changedValues: Settings_Base_metatag = { ...values };
      if (!changedValues.name) {
        delete changedValues.name;
      }
      if (!changedValues.property) {
        delete changedValues.property;
      }
      onSave(changedValues);
      return;
    } catch (err) {
      return {
        [FORM_ERROR]: (err as Error).message,
      };
    }
  };

  return (
    <Modal isOpen={!!metatag} handleClose={onClose}>
      {metatag && (
        <Form
          initialValues={{ ...metatag }}
          onSubmit={handleFormSubmit}
          validate={(values: EditMetatag) => {
            const { name, property } = values;
            const error: Record<string, string> = {};
            if (!name && !property) {
              error[FORM_ERROR] = __(
                'Vyplňte jedno z polí "name" alebo "property"!',
              );
            }
            return Object.keys(error).length > 0 ? error : undefined;
          }}
          render={({
            handleSubmit,
            values,
            error,
            errors,
            pristine,
            touched,
            submitting,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <ModalContent>
                  {(touched?.name || touched?.property) && error ? (
                    <Message danger title={error} />
                  ) : (
                    <Message
                      primary
                      title={__(
                        'Vyplňte jedno z polí "name" alebo "property" a pole "content".',
                      )}
                    />
                  )}
                  <Field
                    label={__('Name')}
                    name="name"
                    info={__('Hodnota tvorí "name" atribút metatagu.')}
                    disabled={!!values?.property}
                    component={FinalFormField}
                    formatOnBlur
                    format={(value?: string) => {
                      return (value || '').trim();
                    }}
                  />
                  <Field
                    label={__('Property')}
                    name="property"
                    info={__('Hodnota tvorí "property" atribút metatagu.')}
                    disabled={!!values?.name}
                    component={FinalFormField}
                    formatOnBlur
                    format={(value?: string) => {
                      return (value || '').trim();
                    }}
                  />
                  <Field
                    label={__('Content')}
                    name="content"
                    info={__('Hodnota tvorí "content" atribút metatagu.')}
                    component={FinalFormField}
                    validate={required}
                    formatOnBlur
                    format={(value?: string) => {
                      return (value || '').trim();
                    }}
                    required
                  />
                </ModalContent>
                <ModalActions>
                  <Button primary basic onClick={onClose}>
                    {__('Zavrieť')}
                  </Button>
                  <Button
                    primary
                    basic
                    type="submit"
                    disabled={
                      submitting ||
                      pristine ||
                      error ||
                      Object.keys(errors || {}).length > 0
                    }
                  >
                    {__('Uložiť')}
                  </Button>
                </ModalActions>
              </form>
            );
          }}
        />
      )}
    </Modal>
  );
};

export default React.memo(EditModal);
