import { AuthProvider } from '@sportnet/auth-react';
import Administration from 'pages/Administration';
import React from 'react';
import { useParams } from 'react-router-dom';
import config from '../../config';
import CmsApi from '../../CmsApi';
import CoreApi from '../../CoreApi';
import PagesApi from '../../PagesApi';

const Authorization: React.FC = () => {
  const [isAuthorized, setIsAuthorized] = React.useState(false);
  const params = useParams<{ appspace: string }>();

  const { appspace: appSpaceFromUrl } = params;

  const handleAuthorize = React.useCallback<
    (exposed: { accessToken: string }) => void
  >(({ accessToken }) => {
    if (accessToken) {
      CmsApi.setToken(accessToken);
      CoreApi.setToken(accessToken);
      PagesApi.setToken(accessToken);
      setIsAuthorized(true);
    }
  }, []);

  React.useEffect(() => {
    if (appSpaceFromUrl) {
      setIsAuthorized(false);
    }
  }, [appSpaceFromUrl]);

  return (
    <AuthProvider
      app={config.APP_ID}
      ppo={appSpaceFromUrl}
      onAuthorize={handleAuthorize}
    >
      {!isAuthorized ? <>Authorizing ...</> : <Administration />}
    </AuthProvider>
  );
};

export default Authorization;
