import { IOption } from '@sportnet/ui/TheSelect/types';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

const SECTION_LEVEL_PADDING = 10;

const CustomLabel = styled.span<{ level: number }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${rem(12)};
  padding-left: ${({ level }) => rem(level * SECTION_LEVEL_PADDING)};
`;

export const renderSectionTreeOptionLabel = (o: IOption) => {
  const { level } = o;
  return (
    <CustomLabel level={level}>
      {level ? `> ` : ''}
      {o.label}
    </CustomLabel>
  );
};
