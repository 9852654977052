import BasicTable from '@sportnet/ui/BasicTable';
import Text from '@sportnet/ui/Text';
import React from 'react';
import { connect } from 'react-redux';
import { getProp } from '@sportnet/utilities';
import { RootState } from '../../../rootReducer';
import { __ } from '../../../utilities';
import { layoutEntitiesSelector } from '../Layouts/selectors';
import { ISectionEntry } from './useSectionEntries';

interface IOwnProps {
  items: ISectionEntry[];
  onClickItem: (item: ISectionEntry) => void;
}

const mapStateToProps = (state: RootState) => ({
  layoutById: layoutEntitiesSelector(state),
});

type IProps = ReturnType<typeof mapStateToProps> & IOwnProps;

const SectionTable: React.FC<IProps> = ({ items, onClickItem, layoutById }) => {
  const getLayoutLabel = (layoutId: string) => {
    if (layoutId === '') {
      return __('Predvolená');
    }
    return getProp(layoutById, [layoutId, 'name'], '');
  };

  return (
    <BasicTable
      columns={[
        {
          id: 'sectionName',
          header: __('Sekcia'),
        },
        {
          id: 'layout',
          header: __('Šablóna'),
          width: 200,
        },
      ]}
      rows={items}
      onClickRow={onClickItem}
      renderRow={(item: ISectionEntry) => [
        <React.Fragment key="name">
          {item.path.join(' / ')}
          {item.path.length > 0 && ` / `}
          <strong>{item.name}</strong>
        </React.Fragment>,
        item.inherited ? (
          <Text key="layout-inherited" faded>
            {getLayoutLabel(item.layoutId)}
          </Text>
        ) : (
          <strong key="layout">{getLayoutLabel(item.layoutId)}</strong>
        ),
      ]}
      rowKey="_id"
    />
  );
};

export default connect(mapStateToProps)(SectionTable);
