import SubmitButton from '@sportnet/ui/Button/Submit';
import FormField from '@sportnet/ui/FormField/redux-form';
import Segment from '@sportnet/ui/Segment';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { ISettingsBaseContactForm } from '../../../library/Pages';
import { RootState } from '../../../rootReducer';
import styled from '../../../theme/styled-components';
import { required, __ } from '../../../utilities';

export const FORM_NAME = 'contact-form';

type IOwnProps = {
  hasSubmitFailed: boolean;
};

export interface IFormData extends ISettingsBaseContactForm {
  enabled?: boolean;
}

const FormFieldsMaxWidthBox = styled.div`
  max-width: ${rem(360)};
`;

const mapStateToProps = (state: RootState) => ({
  values: getFormValues(FORM_NAME)(state) as IFormData,
});

type IProps = IOwnProps &
  InjectedFormProps &
  ReturnType<typeof mapStateToProps>;

const ContactForm: React.FC<IProps> = ({
  submitting,
  handleSubmit,
  hasSubmitFailed,
  values,
}) => {
  const disabled = values && !values.enabled;
  return (
    <form onSubmit={handleSubmit}>
      <Segment raised paddingSize="l">
        <Field
          component={FormField}
          name="enabled"
          type="checkbox"
          label={__('Povoliť kontaktný formulár')}
        />
        {!disabled && (
          <FormFieldsMaxWidthBox>
            <Field
              component={FormField}
              name="recipients"
              label={__('Prijímateľ')}
              format={(v: any) =>
                (v || []).map((a: string) => a.trim()).join(',')
              }
              parse={(v: any) => v.split(',')}
              required
              validate={[required]}
              placeholder={__('napr. admin@domena.sk, info@domena.sk')}
              info={__('Viac prijímateľov oddeľte čiarkou')}
            />
            <Field
              component={FormField}
              name="from"
              label={__('Email, ktorý sa zobrazí odosielateľovi')}
              required
              validate={[required]}
              placeholder={__('napr. info@domena.sk')}
              info={__('Slúži tiež ako email, na ktorý príde odpoveď')}
            />
            <Field
              component={FormField}
              name="fromName"
              label={__('Meno, ktoré sa zobrazí odosielateľovi')}
              required
              validate={[required]}
            />
            <Field
              component={FormField}
              name="description"
              label={__('Popisný text')}
              type="textarea"
              info={__('Zobrazí sa nad kontaktným formulárom')}
            />
            <Field
              component={FormField}
              name="subject"
              type="textarea"
              required
              validate={[required]}
              label={__('Predmet')}
              format={(v: any) => (v || []).join('\n')}
              parse={(v: any) => v.split('\n')}
              placeholder={__('napr. Technická podpora')}
              info={__('Každý predmet na novom riadku')}
            />
          </FormFieldsMaxWidthBox>
        )}
      </Segment>
      <SubmitButton
        successText={__('Uložené!')}
        isSubmitting={submitting}
        isError={hasSubmitFailed}
      >
        {__('Uložiť zmeny')}
      </SubmitButton>
    </form>
  );
};

export default compose(
  reduxForm({
    form: FORM_NAME,
  }),
  connect(mapStateToProps),
)(ContactForm) as any;
