import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html, body, #root {
    margin: 0;
    padding: 0;
    height: 100vh;
  }
  html {
    font-size: 100%;
    font-family: 'Barlow', sans-serif;
  }
  * {
    box-sizing: border-box;
  }
`;
