import { commit, CommitError } from '@sportnet/redux-list';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import {
  CustomThunkDispatch,
  ExtraArgumentType,
} from '../../../configureStore';
import { ILayout } from '../../../library/Pages';
import { RootState } from '../../../rootReducer';
import { updateEntities } from '../../App/actions';
import constants from './constants';

const create = actionCreatorFactory('LAYOUTS');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadLayouts = createAsync<{ appSpace: string }, ILayout[]>(
  'LOAD_LAYOUTS',
  async ({ appSpace }, dispatch, getState, { PagesApi }) => {
    const response = await PagesApi.adminGetLayouts(appSpace);

    const entities: { [key: string]: ILayout } = {};

    response.layouts.forEach(item => {
      entities[item._id!] = item;
    });

    dispatch(
      updateEntities({
        layouts: entities,
      }),
    );

    return response.layouts as ILayout[];
  },
);

export function loadLayoutsList({ appSpace }: { appSpace: string }) {
  return (dispatch: CustomThunkDispatch) => {
    dispatch(
      commit.action({
        listName: constants.LIST_NAME,
        load: async () => {
          try {
            const res = await dispatch(loadLayouts.action({ appSpace }));
            return {
              total: res.length,
              results: res.map(i => i._id),
              nextOffset: null,
            };
          } catch (e) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
}
