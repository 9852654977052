import FormField from '@sportnet/ui/FormField/redux-form';
import * as React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import config from '../../../../config';
import { RootState } from '../../../../rootReducer';
import { __ } from '../../../../utilities';
import { usedColorsSelector } from '../selectors';
import { useAuth } from '@sportnet/auth-react';

const mapStateToProps = (state: RootState) => ({
  usedColors: usedColorsSelector(state),
});

type IProps = ReturnType<typeof mapStateToProps>;

const GeneralFields: React.FC<IProps> = ({ usedColors }) => {
  const { ppo: appSpace, accessToken } = useAuth();
  return (
    <>
      <Field
        component={FormField}
        name="settings.theme.color.primary"
        type="colorInput"
        label={__('Primárna farba')}
        propagateChangesImmediately
        colors={usedColors}
      />
      <Field
        component={FormField}
        name="settings.logo"
        label={__('Logo')}
        type="image"
        auth={{
          appId: config.APP_ID,
          appSpace,
          token: accessToken,
        }}
      />
      <Field
        component={FormField}
        name="settings.logoSmall"
        label={__('Logo malé (nepovinné)')}
        type="image"
        auth={{
          appId: config.APP_ID,
          appSpace,
          token: accessToken,
        }}
      />
      <Field
        component={FormField}
        name="settings.favicon"
        label={__('Favicon')}
        type="image"
        auth={{
          appId: config.APP_ID,
          appSpace,
          token: accessToken,
        }}
      />
      <Field
        component={FormField}
        name="settings.theme.app.bgColor"
        type="colorInput"
        label={__('Farba pozadia')}
        propagateChangesImmediately
        colors={usedColors}
      />
      <Field
        component={FormField}
        name="settings.theme.app.secondaryBgColor"
        type="colorInput"
        label={__('Skundárna farba pozadia')}
        propagateChangesImmediately
        colors={usedColors}
      />
      <Field
        component={FormField}
        name="settings.theme.app.textColor"
        type="colorInput"
        label={__('Farba textu')}
        propagateChangesImmediately
        colors={usedColors}
      />
      <Field
        component={FormField}
        name="settings.theme.app.secondaryTextColor"
        type="colorInput"
        label={__('Sekundárna farba textu')}
        propagateChangesImmediately
        colors={usedColors}
      />
      <Field
        component={FormField}
        name="settings.pageTitle"
        label={__('Nadpis stránky')}
      />
    </>
  );
};

export default connect(mapStateToProps)(GeneralFields);
