import { createSelector } from 'reselect';
import { ISectionTree, SectionId } from '../../../library/App';
import { normalizeSectionParent } from '../../../utilities';
import { layoutsListSelector } from '../Layouts/selectors';
import { sectionTreeSelector } from '../selectors';

export interface ISectionEntry {
  _id: SectionId;
  name: string;
  path: string[];
  parent: SectionId[];
}

export const sectionEntriesSelector = createSelector(
  sectionTreeSelector,
  (tree): ISectionEntry[] => {
    const result: ISectionEntry[] = [];

    const traverseTree = (sections: ISectionTree, path: string[] = []) => {
      sections.forEach(section => {
        result.push({
          _id: section._id!,
          name: section.name!,
          path,
          parent: normalizeSectionParent(section.parent),
        });
        if (section.sections && section.sections.length > 0) {
          traverseTree(section.sections, [...path, section.name!]);
        }
      });
    };

    traverseTree(tree);
    return result;
  },
);

export const layoutOptionsSelector = createSelector(
  layoutsListSelector,
  list => {
    return list.map(layout => ({ value: layout._id!, label: layout.name }));
  },
);
