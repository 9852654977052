import * as React from 'react';
import { rem } from 'polished';
import Loader from '@sportnet/ui/Loader';
import styled from '../../theme/styled-components';

const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => rem(theme.grid.gutterWidth * 2)} 0;
`;

const ContentLoader = () => (
  <Wrapper>
    <Loader size="xl" />
  </Wrapper>
);
export default ContentLoader;
