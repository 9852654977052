import NotFound from '@sportnet/ui/NotFound';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ContentLoader from '../../../components/ContentLoader';
import PagesConnector from '../../../containers/PagesConnector';
import { IPublicSettings } from '../../../library/Pages';
import PagesApi from '../../../PagesApi';
import styled from '../../../theme/styled-components';
import { emptyFn, __ } from '../../../utilities';
import { useAuth } from '@sportnet/auth-react';

const CenteredNotFound = styled(NotFound)`
  flex-grow: 1;
`;

type IOwnProps = {};

const SiteDemo: React.FC<IOwnProps> = () => {
  const [publicSettings, setPublicSettings] =
    React.useState<null | IPublicSettings>(null);

  const history = useHistory();

  const { ppo: appSpace } = useAuth();

  // load detail
  React.useEffect(() => {
    if (!appSpace) {
      return;
    }
    (async () => {
      try {
        const res = await PagesApi.publicGetSettings(appSpace);
        setPublicSettings(res);
      } catch (e) {
        console.error('Settings cannot be retrieved');
      }
    })();
  }, [setPublicSettings, appSpace]);

  if (!publicSettings) {
    return <ContentLoader />;
  }

  if (appSpace && publicSettings && !publicSettings.domain) {
    return (
      <CenteredNotFound
        title={__('Nemáte nastavenú doménu')}
        onClickAction={() => history.push(`/admin/${appSpace}/domain`)}
        actionLabel={__('Prejsť na nastavenie domény')}
      />
    );
  }

  const newSettings: IPublicSettings & { CSMAppSpace: string } = {
    CSMAppSpace: appSpace ?? '',
    ...publicSettings,
  };

  return appSpace ? (
    <PagesConnector
      baseUrl={`https://${publicSettings.domain}/`}
      onClickArea={emptyFn}
      settings={newSettings}
    />
  ) : null;
};

export default SiteDemo;
