import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import Segment from '@sportnet/ui/Segment';
import React from 'react';
import { compose } from 'redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import Accordion from '../../../../components/Accordion';
import AccordionItem from '../../../../components/Accordion/AccordionItem';
import {
  IAdminCreateLayout,
  IAdminEditLayout,
} from '../../../../library/Pages';
import { required, __ } from '../../../../utilities';
import { FORM_NAME } from './constants';
import FooterFields from './FooterFields';
import GeneralFields from './GeneralFields';
import HeaderFields from './HeaderFields';
import MetatagsFields from './MetatagsFields';

type IOwnProps = {};

type Props = IOwnProps;

export interface IFormData extends IAdminEditLayout, IAdminCreateLayout {}

const DetailForm: React.FC<
  Props & InjectedFormProps<IFormData, Props>
> = () => {
  const [activeAccordionItem, setActiveAccordionItem] = React.useState<
    string | null
  >(null);

  return (
    <Segment noBottomGutter>
      <Field
        label={__('Názov')}
        name="name"
        component={FormFieldRedux}
        validate={required}
        required
      />
      <Field
        label={__('Použiť ako predvolenú')}
        name="default"
        type="checkbox"
        component={FormFieldRedux}
        info={__(
          'Predvolená šablóna bude použitá pre podstránky ako vyhľadávanie, zoznamy, nezaradené články a v prípadoch kedy sekcia podstránky nemá nastavenú šablónu.',
        )}
      />
      <Accordion opened={activeAccordionItem} onChange={setActiveAccordionItem}>
        <AccordionItem id="general" title={__('Všeobecné nastavenia')}>
          <GeneralFields />
        </AccordionItem>
        <AccordionItem id="header" title={__('Hlavička')}>
          <HeaderFields formName={FORM_NAME} />
        </AccordionItem>
        <AccordionItem id="footer" title={__('Pätička')}>
          <FooterFields formName={FORM_NAME} />
        </AccordionItem>
        <AccordionItem id="metatags" title={__('Metatagy')}>
          <MetatagsFields />
        </AccordionItem>
      </Accordion>
    </Segment>
  );
};

export default compose(
  reduxForm<IFormData, Props>({
    form: FORM_NAME,
    destroyOnUnmount: true,
  }),
  React.memo,
)(DetailForm);
