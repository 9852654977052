/* tslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

export type Article_Author = {
  sportnetId?: string;

  name: string;
};
export type Allowed_Users_List = {
  users?: Array<{
    user_id?: string;

    subsection_access?: boolean;
  }>;
};
export type Section_Path = {
  contentspace?: string;

  perex?: string | null;

  name?: string;

  parent?: Array<number>;

  show?: boolean;

  is_private?: boolean;

  url?: string | null;

  content?: Content;

  uniqid_key?: string;

  redirecturl?: string | null;

  _id?: number;

  order?: number;

  uniqid?: string;

  settings?: Variables;
};
export type Section_Path_List = {
  path?: Array<Section_Path>;
};
export type URL_Map = {
  url?: string | null;

  urltype?: string;

  contentspace?: string;

  object_id?: number;

  expanded_object?: {};

  location?: string;

  permanent?: boolean;
};
export type Smarttag = {
  key: string;

  title?: string;

  values: Array<{
    key: string;

    title?: string | null;
  }>;
};
export type Article_Draft = {
  created?: string;

  createdBy?: {
    id?: string | null;

    displayName?: string | null;
  };
};
export type Article_Discussion_Stats = {
  _id?: number;

  contentspace?: string;

  externalIds?: Array<{
    service?: string;

    externalId?: string;

    lastUpdate?: string;

    error?: string;
  }>;

  discussion?: {
    type?: string;

    discussionId?: number;

    postCount?: number;

    active?: boolean;

    url?: string;
  };
};
export type Article = {
  _id?: number;

  owner?: {
    sportnetId?: string | null;

    name?: string | null;
  };

  authors?: Array<Article_Author>;

  contentspace?: string;

  doctype?: string;

  doctypeExpanded?: Doctype;

  is_private?: boolean;

  locked?: boolean;

  name?: string;

  created?: string;

  modified?: string | null;

  perex?: string | null;

  picture?: Picture;

  publication_groups?: Array<string>;

  redirect?: string | null;

  sectionid?: Array<number> | number | null;

  stats_view_total?: number;

  sfz?: Array<{}>;

  smarttags?: Array<Smarttag>;

  source?: string | null;

  tags?: Array<{}>;

  url?: string | null;

  valid_from?: string | null;

  updated_at?: string | null;

  widgets?: Content;

  draft?: Article_Draft;

  layout?: string;

  externalIds?: Array<{
    service?: string;

    externalId?: string;

    lastUpdate?: string;

    error?: string;
  }>;

  meta?: {
    title?: string;

    description?: string;
  };

  discussion?: {
    type?: string;

    discussionId?: number;

    postCount?: number;

    active?: boolean;

    url?: string;
  };

  section?: Section;

  locks?: Array<Article_Lock>;

  highlights?: Array<{
    path?: string;

    score?: number;

    texts?: Array<{
      value?: string;

      type?: 'hit' | 'text';
    }>;
  }>;
};
export type Article_Public_List = {
  _id?: number;

  authors?: Array<Article_Author>;

  contentspace?: string;

  doctype?: string;

  doctypeExpanded?: Doctype;

  is_private?: boolean;

  locked?: boolean;

  name?: string;

  modified?: string | null;

  perex?: string | null;

  picture?: Picture;

  publication_groups?: Array<string>;

  redirect?: string | null;

  sectionid?: Array<number> | number | null;

  stats_view_total?: number;

  smarttags?: Array<Smarttag>;

  source?: string | null;

  url?: string | null;

  valid_from?: string | null;

  meta?: {
    title?: string;

    description?: string;
  };

  discussion?: {
    type?: string;

    discussionId?: number;

    postCount?: number;

    active?: boolean;

    url?: string;
  };
};
export type ArticleLite = {
  _id?: number;

  owner?: {
    sportnetId?: string | null;

    name?: string | null;
  };

  authors?: Array<Article_Author>;

  contentspace?: string;

  doctype?: string;

  doctypeExpanded?: Doctype;

  is_private?: boolean;

  locked?: boolean;

  name?: string;

  created?: string;

  modified?: string | null;

  perex?: string | null;

  picture?: Picture;

  publication_groups?: Array<string>;

  redirect?: string | null;

  sectionid?: Array<number> | number | null;

  stats_view_total?: number;

  smarttags?: Array<Smarttag>;

  source?: string | null;

  url?: string | null;

  valid_from?: string | null;

  meta?: {
    title?: string;

    description?: string;
  };

  discussion?: {
    type?: string;

    discussionId?: number;

    postCount?: number;

    active?: boolean;

    url?: string;
  };
};
export type Widget = {
  type: string;
};
export type Admin_Articles_List = {
  articles: Array<{
    _id?: number;

    owner?: {
      sportnetId?: string | null;

      name?: string | null;
    };

    authors?: Array<Article_Author>;

    contentspace?: string;

    doctype?: string;

    doctypeExpanded?: Doctype;

    is_private?: boolean;

    locked?: boolean;

    name?: string;

    created?: string;

    modified?: string | null;

    perex?: string | null;

    picture?: Picture;

    publication_groups?: Array<string>;

    redirect?: string | null;

    sectionid?: Array<number> | number | null;

    stats_view_total?: number;

    sfz?: Array<{}>;

    smarttags?: Array<Smarttag>;

    source?: string | null;

    tags?: Array<{}>;

    url?: string | null;

    valid_from?: string | null;

    updated_at?: string | null;

    widgets?: Content;

    draft?: Article_Draft;

    layout?: string;

    externalIds?: Array<{
      service?: string;

      externalId?: string;

      lastUpdate?: string;

      error?: string;
    }>;

    meta?: {
      title?: string;

      description?: string;
    };

    discussion?: {
      type?: string;

      discussionId?: number;

      postCount?: number;

      active?: boolean;

      url?: string;
    };

    section?: Section;

    locks?: Array<Article_Lock>;

    highlights?: Array<{
      path?: string;

      score?: number;

      texts?: Array<{
        value?: string;

        type?: 'hit' | 'text';
      }>;
    }>;

    note?: string;
  }>;

  nextOffset?: number | null;

  total?: number;

  limit?: number;

  offset?: number;
};
export type Articles_List = {
  articles: Array<Article>;

  nextOffset?: number | null;

  total?: number;

  limit?: number;

  offset?: number;
};
export type Articles_Public_List = {
  articles: Array<Article_Public_List>;

  nextOffset?: number | null;

  total?: number;

  limit?: number;

  offset?: number;
};
export type Articles_List_Lite = {
  articles: Array<ArticleLite>;

  nextOffset?: number | null;

  total?: number;

  limit?: number;

  offset?: number;
};
export type Article_Post = {
  doctype?: string;

  is_private?: boolean;

  locked?: boolean;

  name: string;

  perex?: string | null;

  picture?: Picture;

  publication_groups?: Array<string>;

  redirect?: string | null;

  sectionid?: Array<number> | null;

  smarttags?: Array<Smarttag>;

  source?: string | null;

  url?: string | null;

  urlSuggestion?: string;

  valid_from?: string | null;

  updated_at?: string | null;

  widgets?: Content;

  layout?: string;

  authors?: Array<Article_Author>;

  note?: string;

  meta?: {
    title?: string;

    description?: string;
  };
};
export type Article_Put = {
  doctype?: string;

  locked?: boolean;

  name?: string;

  perex?: string | null;

  picture?: Picture;

  is_private?: boolean;

  publication_groups?: Array<string>;

  redirect?: string | null;

  sectionid?: Array<number> | null;

  smarttags?: Array<Smarttag>;

  source?: string | null;

  url?: string | null;

  valid_from?: string | null;

  updated_at?: string | null;

  widgets?: Content;

  layout?: string;

  authors?: Array<Article_Author>;

  note?: string;

  meta?: {
    title?: string;

    description?: string;
  };
};
export type Section = {
  _id?: number;

  contentspace?: string;

  is_private?: boolean;

  name?: string;

  heading?: string | null;

  order?: number;

  parent?: Array<number>;

  perex?: string | null;

  icon?: Picture;

  picture?: Picture;

  sectionArticles?: SectionArticles;

  redirecturl?: string | null;

  content?: Content;

  show?: boolean;

  smarttags?: Array<Smarttag>;

  uniqid?: string;

  url?: string | null;

  publication_groups?: Array<string>;

  settings?: Variables;

  meta?: {
    title?: string;

    description?: string;
  };
};
export type Variables = {};
export type SectionPublic = {
  _id?: number;

  contentspace?: string;

  is_private?: boolean;

  name?: string;

  heading?: string | null;

  order?: number;

  parent?: Array<number>;

  perex?: string | null;

  icon?: Picture;

  picture?: Picture;

  redirecturl?: string | null;

  show?: boolean;

  smarttags?: Array<Smarttag>;

  uniqid?: string;

  url?: string | null;

  settings?: Variables;

  sections?: Array<{}>;

  meta?: {
    title?: string;

    description?: string;
  };
};
export type SectionPublicDetail = {
  _id?: number;

  contentspace?: string;

  is_private?: boolean;

  name?: string;

  heading?: string | null;

  order?: number;

  parent?: Array<number>;

  perex?: string | null;

  icon?: Picture;

  picture?: Picture;

  redirecturl?: string | null;

  show?: boolean;

  smarttags?: Array<Smarttag>;

  uniqid?: string;

  url?: string | null;

  settings?: Variables;

  sections?: Array<{}>;

  meta?: {
    title?: string;

    description?: string;
  };

  sectionArticles?: SectionArticles;

  content?: Content;

  publication_groups?: Array<string>;
};
export type Section_List = {
  sections?: Array<Section>;
};
export type SectionPublic_List = {
  sections?: Array<SectionPublic>;
};
export type SectionPublic_Tree = {
  tree?: Array<SectionPublic>;
};
export type SectionAdmin_Tree = {
  tree?: Array<Section>;
};
export type Section_Post = {
  is_private?: boolean;

  name: string;

  heading?: string | null;

  parent?: number | null;

  perex?: string | null;

  icon?: Picture;

  picture?: Picture;

  sectionArticles?: SectionArticles;

  redirecturl?: string | null;

  show?: boolean;

  smarttags?: Array<Smarttag>;

  uniqid?: string;

  url: string;

  publication_groups?: Array<string>;

  content: Content;

  settings?: Variables;
};
export type Section_Put = {
  _id?: number;

  is_private?: boolean;

  name?: string;

  heading?: string | null;

  perex?: string | null;

  icon?: Picture;

  picture?: Picture;

  sectionArticles?: SectionArticles;

  redirecturl?: string | null;

  show?: boolean;

  smarttags?: Array<Smarttag>;

  uniqid?: string;

  url?: string | null;

  publication_groups?: Array<string>;

  newParent?: number | null;

  content?: Content;

  settings?: Variables;

  meta?: {
    title?: string;

    description?: string;
  };
};
export type Sections_Order = {
  order?: Array<Array<number>>;
};
export type Static_Content_Public = Static_Content;
export type Static_Content = {
  _id?: string;

  cid?: string;

  name?: string;

  content?: Content;
};
export type Content = Array<{}>;
export type Static_Contents_List = {
  static_content?: Array<{
    cid?: string;

    name?: string;
  }>;
};
export type Static_Content_PostPut = {
  name: string;

  cid: string;

  content: Content;
};
export type Doctype = {
  id: string;

  title: string;

  defaultPicture?: Picture;

  overrideAuthorBySmarttagKey?: string;

  showCreatedBy?: boolean;

  top?: {
    showAuthor?: boolean;

    showId?: boolean;

    showDate?: boolean;

    showSmarttags?: boolean;
  };

  bottom?: {
    showAuthor?: boolean;

    showSmarttags?: boolean;
  };

  social?: {};

  relatedArticles?: {
    show?: boolean;

    limit?: number;
  };

  defaultWidgets?: Content;
};
export type Doctype_Put = {
  id?: string;

  title?: string;

  defaultPicture?: Picture;

  overrideAuthorBySmarttagKey?: string;

  showCreatedBy?: boolean;

  top?: {
    showAuthor?: boolean;

    showId?: boolean;

    showDate?: boolean;

    showSmarttags?: boolean;

    showSocial?: boolean;
  };

  bottom?: {
    showAuthor?: boolean;

    showSmarttags?: boolean;

    showSocial?: boolean;
  };

  social?: {};

  relatedArticles?: {
    show?: boolean;

    limit?: number;
  };

  defaultWidgets?: Content;
};
export type Doctypes = Array<Doctype>;
export type Preview_Post = {
  type: 'article';

  valid_to: string;

  data: {};
};
export type Preview = {
  type: 'article';

  valid_to: string;

  data: {};

  _id: string;
};
export type Confirmation_Message = {
  message: string;
};
export type ListingType = 'system' | 'article-tabs';
export type Listing_Patch = {
  name?: string;

  type?: ListingType;

  markers?: Array<number>;

  articles?: Array<{
    _id: number;

    name?: string;
  }>;

  articleFilter?: Array<{
    type: string;
  }>;

  priority?: number | null;
};
export type Listing_Post = {
  listingId: string;

  name: string;

  markers?: Array<number>;

  type?: ListingType;

  articles?: Array<{
    _id: number;

    name?: string;
  }>;

  articleFilter?: Array<{
    type: string;
  }>;
};
export type Listing = {
  _id: string;

  appSpace?: string;

  listingId: string;

  contentspace?: string;

  name?: string;

  type?: ListingType;

  markers?: Array<number>;

  articles?: Array<{
    _id: number;

    name?: string;
  }>;

  articleFilter?: Array<{
    type: string;
  }>;

  created?: string;

  modified?: string | null;

  priority?: number;
};
export type Listing_Fixed_Articles = {
  articles: Array<{
    _id?: number;

    owner?: {
      sportnetId?: string | null;

      name?: string | null;
    };

    authors?: Array<Article_Author>;

    contentspace?: string;

    doctype?: string;

    doctypeExpanded?: Doctype;

    is_private?: boolean;

    locked?: boolean;

    name?: string;

    created?: string;

    modified?: string | null;

    perex?: string | null;

    picture?: Picture;

    publication_groups?: Array<string>;

    redirect?: string | null;

    sectionid?: Array<number> | number | null;

    stats_view_total?: number;

    sfz?: Array<{}>;

    smarttags?: Array<Smarttag>;

    source?: string | null;

    tags?: Array<{}>;

    url?: string | null;

    valid_from?: string | null;

    updated_at?: string | null;

    widgets?: Content;

    draft?: Article_Draft;

    layout?: string;

    externalIds?: Array<{
      service?: string;

      externalId?: string;

      lastUpdate?: string;

      error?: string;
    }>;

    meta?: {
      title?: string;

      description?: string;
    };

    discussion?: {
      type?: string;

      discussionId?: number;

      postCount?: number;

      active?: boolean;

      url?: string;
    };

    section?: Section;

    locks?: Array<Article_Lock>;

    highlights?: Array<{
      path?: string;

      score?: number;

      texts?: Array<{
        value?: string;

        type?: 'hit' | 'text';
      }>;
    }>;

    listingName?: string;
  }>;
};
export type Listings_List = {
  listings: Array<Listing>;

  nextOffset?: number | null;

  total: number;

  limit: number;

  offset: number;
};
export type URLMap_List = {
  urlmap: Array<URLMapUrl>;

  nextOffset?: number | null;

  total: number;

  limit: number;

  offset: number;
};
export type URLMapUrl = {
  url: string;

  urltype: string;

  location?: string;

  permanent?: boolean;
};
export type URLMapUrl_Put = {
  location: string;

  permanent: boolean;
};
export type URLMapUrl_Post = {
  url: string;

  location: string;

  permanent: boolean;
};
export type Picture = {
  public_url?: string;

  media_url?: string;

  author?: string;

  source?: string | null;
};
export type SectionArticles = {
  header?: string;

  display?: string;

  contextual?: boolean;

  articleFilter?: Array<{
    type: string;
  }>;
};
export type Expand_Content = {
  content: Content;
};
export type Error = {
  code: number;

  name: string;

  description: string | null;

  payload?: object | null;

  userinfo?: string;
};
export type Error_Private_Article = {
  code: number;

  name: string;

  description: string | null;

  payload?: {
    data?: Article;
  };

  userinfo?: string;
};
export type Error_Private_Section = {
  code: number;

  name: string;

  description: string | null;

  payload?: {
    data?: Section;
  };

  userinfo?: string;
};
export type Error_Private_URL_Map = {
  code: number;

  name: string;

  description: string | null;

  payload?: {
    data?: URL_Map;
  };

  userinfo?: string;
};
export type Article_Lock = {
  sportnetId?: string;

  displayName?: string;

  lockedTo?: string | null;
};
export type Article_Locks_List = {
  locks: Array<{
    sportnetId?: string;

    displayName?: string;

    lockedTo?: string | null;
  }>;

  editEnabled?: boolean;
};
export type External_Article = {
  _id?: string;

  externalArticleId?: string;

  state?: 'new' | 'deleted' | 'taken';

  name?: string;

  perex?: string | null;

  externalSource?: 'TASR' | 'SITA';

  published?: string;

  html?: string;

  contentSpace?: string;
};
export type External_Articles_List = {
  externalArticles: Array<External_Article>;

  nextOffset?: number | null;

  total?: number;

  limit?: number;

  offset?: number;
};
export type External_Article_Put = {
  state: 'new' | 'deleted' | 'taken';
};
export type External_Articles_Count = {
  externalArticlesCount?: number;
};
export type Articles_Bulk_Update = {
  smarttags?: Array<Smarttag>;

  sectionId?: Array<number> | null;
};
export type Articles_Bulk_Update_Response = {
  modifiedCount?: number;

  matchedCount?: number;
};
export type Translator_TextToTranslate_Item = {
  sourceLang?: string;

  targetLang: string;

  name: string;

  text: string;

  contentType: 'string' | 'html';
};
export type Translator_TranslatedData_Item = {
  name: string;

  text: string;

  detectedSourceLanguage: string;

  translated: string;
};
export type Translator_Translate_Request_Body = {
  textToTranslateList: Array<Translator_TextToTranslate_Item>;
};
export type Translator_TranslatedData = {
  bigTable: boolean;

  totalCount: number;

  data: Array<Translator_TranslatedData_Item>;
};
export type Translator_LanguageSettings_Item = {
  lang: string;

  name: string;
};
export type Translator_LanguageSettings = {
  sourceLang: Array<Translator_LanguageSettings_Item>;

  targetLang: Array<Translator_LanguageSettings_Item>;
};

class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}

/**
 *
 * @class Api
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class Api {
  protected baseUrl: string = 'https://cms.sportnet.online/api/v3';
  protected headers: { name: string; value: string }[] = [];
  protected token: string = '';

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public setHeaders(headers: { name: string; value: string }[]) {
    this.headers = headers;
  }

  public setToken(token: string) {
    this.token = token;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    if (this.headers.length > 0) {
      this.headers.forEach((h) => {
        headers.append(h.name, h.value);
      });
    }

    const response = await fetch(urlWithParams, { method, headers, body });

    if (response.status === 204) return response;

    if (response.ok) {
      const responseContentType =
        (response.headers && response.headers.get('Content-Type')) || '';
      if (responseContentType.includes('application/json')) {
        return response.json();
      }
      return response;
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  /**
   * Returns List of Sections
   * @method
   * @name Api#getSections
   */
  getSections(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {} = {},
  ): Promise<Section_List> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/sections';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Insert New Section with Given Properties
   * @method
   * @name Api#postSection
   */
  postSection(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {} = {},
    body: Section_Post,
  ): Promise<Section> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/sections';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Returns Detail of Section
   * @method
   * @name Api#getSection
   */
  getSection(
    appId: string,
    appSpace: string,
    contentDivider: string,
    sectionId: number,
    parameters: {} = {},
  ): Promise<Section> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/sections/{section_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{section_id}', sectionId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Insert New Section with Given Properties As Subsection to Parent ID
   * @method
   * @name Api#postSubsection
   */
  postSubsection(
    appId: string,
    appSpace: string,
    contentDivider: string,
    sectionId: number,
    parameters: {} = {},
    body: Section_Post,
  ): Promise<Section> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/sections/{section_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{section_id}', sectionId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Edit Section
   * @method
   * @name Api#editSection
   */
  editSection(
    appId: string,
    appSpace: string,
    contentDivider: string,
    sectionId: number,
    parameters: {} = {},
    body: Section_Put,
  ): Promise<Section> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/sections/{section_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{section_id}', sectionId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Delete Section
   * @method
   * @name Api#deleteSection
   */
  deleteSection(
    appId: string,
    appSpace: string,
    contentDivider: string,
    sectionId: number,
    parameters: {} = {},
  ): Promise<Confirmation_Message> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/sections/{section_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{section_id}', sectionId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get List of Subsections for Given Parent Section ID
   * @method
   * @name Api#getSubsections
   */
  getSubsections(
    appId: string,
    appSpace: string,
    contentDivider: string,
    sectionId: number,
    parameters: {} = {},
  ): Promise<Section_List> {
    let path =
      '/admin/{appId}:{appSpace}:{contentDivider}/sections/{section_id}/sections';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    path = path.replace('{section_id}', sectionId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Fetch Admin Sections Tree
   * @method
   * @name Api#getAdminSectionsTree
   */
  getAdminSectionsTree(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {} = {},
  ): Promise<SectionAdmin_Tree> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/sections-tree';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Change Ordering of Sections In Current Level
   * @method
   * @name Api#changeOrderOfSections
   */
  changeOrderOfSections(
    appId: string,
    appSpace: string,
    contentDivider: string,
    parameters: {} = {},
    body: Sections_Order,
  ): Promise<Confirmation_Message> {
    let path = '/admin/{appId}:{appSpace}:{contentDivider}/sections-order';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{contentDivider}', contentDivider.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
}

export default new Api();
export { Api };
