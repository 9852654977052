import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';
import { getProp } from '@sportnet/utilities';
import { RootState } from '../../../rootReducer';
import { isValidColor } from '../../../utilities';
import { layoutEntitiesSelector } from '../Layouts/selectors';
import { IFormData } from './LayoutForm';
import { FORM_NAME } from './LayoutForm/constants';

const detailDomain = (state: RootState) => state.layoutsById;

export const detailIsFetchingSelector = (layoutId: string) =>
  createSelector(detailDomain, domain =>
    layoutId in domain ? domain[layoutId].isFetching : false,
  );

export const detailSelector = (layoutId: string) =>
  createSelector(layoutEntitiesSelector, entities =>
    layoutId in entities ? entities[layoutId] : null,
  );

export const detailErrorSelector = (layoutId: string) =>
  createSelector(detailDomain, domain =>
    layoutId in domain ? domain[layoutId].error : null,
  );

function getColorsFromObject(obj: { [key: string]: any }): string[] {
  return Object.values(obj).reduce((acc, propValue) => {
    if (typeof propValue === 'string' && isValidColor(propValue)) {
      acc.push(propValue);
    }
    return acc;
  }, []);
}

export const usedColorsSelector = createSelector(
  getFormValues(FORM_NAME),
  (layout: Partial<IFormData>) => {
    const colors: string[] = [
      ...getColorsFromObject(
        getProp(layout, ['settings', 'header', 'config'], {}),
      ),
      ...getColorsFromObject(
        getProp(layout, ['settings', 'footer', 'config'], {}),
      ),
      ...getColorsFromObject(
        getProp(layout, ['settings', 'theme', 'color'], {}),
      ),
      ...getColorsFromObject(getProp(layout, ['settings', 'theme', 'app'], {})),
    ];

    return Array.from(new Set(colors));
  },
);
