import Domains from '@sportnet/ui/Domains';
import HeaderBar from '@sportnet/ui/HeaderBar';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import * as React from 'react';
import { connect } from 'react-redux';
import { getProp } from '@sportnet/utilities';
import ContentLoader from '../../../components/ContentLoader';
import config from '../../../config';
import { RootState } from '../../../rootReducer';
import { __ } from '../../../utilities';
import { activeAppspaceIdSelector, tokenSelector } from '../../App/selectors';
import {
  deleteSettingsSegment,
  loadSettings,
  saveSettings,
} from '../../Editor/actions';
import {
  settingsIsFetchingSelector,
  settingsSelector,
} from '../../Editor/selectors';
import { useAuth } from '@sportnet/auth-react';

type IOwnProps = {};

const mapStateToProps = (state: RootState) => ({
  activeAppspaceId: activeAppspaceIdSelector(state),
  settings: settingsSelector(state),
  settingsIsFetching: settingsIsFetchingSelector(state),
  token: tokenSelector(state),
});

const mapDispatchToProps = {
  saveSettings: saveSettings.action,
  loadSettings: loadSettings.action,
  deleteSettingsSegment: deleteSettingsSegment.action,
};

type IProps = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const C: React.FC<IProps> = ({
  activeAppspaceId,
  settings,
  loadSettings,
  saveSettings,
  deleteSettingsSegment,
  settingsIsFetching,
}) => {
  const { ppo: appSpace, accessToken: token } = useAuth();

  React.useEffect(() => {
    if (!appSpace) {
      return;
    }
    loadSettings({ appSpace });
  }, [loadSettings, appSpace]);

  return (
    <ScrollLayout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__('Doména')}</HeaderBar.Header>
        </HeaderBar>
      }
    >
      {settingsIsFetching && !settings ? (
        <ContentLoader />
      ) : (
        <Segment noBottomGutter>
          {appSpace && settings && (
            <Domains
              accessToken={token!}
              domain={getProp(settings, ['domain'], undefined)!}
              appId="pages"
              appSpace={activeAppspaceId}
              testDomainPostfix={config.TEST_DOMAIN_POSTFIX}
              onChange={v => {
                if (v === null) {
                  deleteSettingsSegment({
                    appSpace,
                    settingsSegment: 'domain',
                  });
                } else {
                  saveSettings({
                    appSpace,
                    settings: {
                      domain: v._id,
                    },
                  });
                }
              }}
            />
          )}
        </Segment>
      )}
    </ScrollLayout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(C as any);
