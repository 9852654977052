import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as listReducer } from '@sportnet/redux-list';
import { applicationReducer, entitiesReducer } from './pages/App/reducer';
import { layoutsByIdReducer } from './pages/Editor/Layout/reducer';
import { sectionTreeReducer, settingsReducer } from './pages/Editor/reducer';

const rootReducer = combineReducers({
  application: applicationReducer,
  entities: entitiesReducer,
  form: formReducer,
  list: listReducer,
  sectionTree: sectionTreeReducer,
  settings: settingsReducer,
  layoutsById: layoutsByIdReducer,
});

export default rootReducer;

export type RootState = {
  application: ReturnType<typeof applicationReducer>;
  entities: ReturnType<typeof entitiesReducer>;
  form: ReturnType<typeof formReducer>;
  list: ReturnType<typeof listReducer>;
  sectionTree: ReturnType<typeof sectionTreeReducer>;
  settings: ReturnType<typeof settingsReducer>;
  layoutsById: ReturnType<typeof layoutsByIdReducer>;
};
