import { IAppPublic } from '@sportnet/ui/Authorization/types';
import { AnyAction } from 'redux';
import { getProp } from '@sportnet/utilities';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Section } from '../../CmsApi';
import {
  IApplicationInfo,
  IAppSpace,
  IUser,
  Writeable,
} from '../../library/App';
import { ILayout } from '../../library/Pages';
import * as actions from './actions';

type EntitiesState = Readonly<{
  sections: Readonly<{
    [key: string]: Readonly<Section>;
  }>;
  layouts: Readonly<{
    [key: string]: Readonly<ILayout>;
  }>;
}>;

export const entitiesReducer = (
  state: EntitiesState = {
    sections: {},
    layouts: {},
  },
  action: AnyAction,
): EntitiesState => {
  if (getProp(action.payload, ['result', 'entities'])) {
    return Object.keys(action.payload.result.entities).reduce(
      (acc: any, entity: keyof EntitiesState) => {
        acc[entity] = Object.keys(
          action.payload.result.entities[entity],
        ).reduce(
          (innerAcc: Writeable<EntitiesState[keyof EntitiesState]>, id) => {
            innerAcc[id] = {
              ...innerAcc[id],
              ...action.payload.result.entities[entity][id],
            };
            return innerAcc;
          },
          { ...getProp(state, [entity], {}) },
        );
        return acc;
      },
      { ...state },
    );
  }
  return state;
};

export type ApplicationState = Readonly<{
  applicationInfo: IApplicationInfo | null;
  appspaces: IAppSpace[];
  apps: IAppPublic[];
  authUser: IUser | null;
  activeAppspace: string;
  breadcrumbs: ReadonlyArray<JSX.Element | string>;
  token: string;
}>;

const INITIAL_STATE: ApplicationState = {
  applicationInfo: null,
  appspaces: [],
  apps: [],
  authUser: null,
  activeAppspace: '',
  breadcrumbs: [],
  token: '',
};

export const applicationReducer = reducerWithInitialState(INITIAL_STATE)
  .case(
    actions.setToken,
    (state, token): ApplicationState => ({
      ...state,
      token,
    }),
  )
  .case(
    actions.setAppspaces,
    (state, appspaces): ApplicationState => ({
      ...state,
      appspaces,
    }),
  )
  .case(
    actions.setApps,
    (state, apps): ApplicationState => ({
      ...state,
      apps,
    }),
  )
  .case(
    actions.setActiveAppspace,
    (state, activeAppspace): ApplicationState => ({
      ...state,
      activeAppspace,
    }),
  )
  .case(
    actions.setAuthUser,
    (state, authorizationResponse): ApplicationState => ({
      ...state,
      authUser: authorizationResponse.user,
    }),
  )
  .case(
    actions.setApplicationInfo,
    (state, applicationInfo): ApplicationState => ({
      ...state,
      applicationInfo,
    }),
  )
  .case(
    actions.removeActiveAppspace,
    (state): ApplicationState => ({
      ...state,
      activeAppspace: '',
    }),
  )
  .case(
    actions.setBreadcrumbs,
    (state, breadcrumbs): ApplicationState => ({
      ...state,
      breadcrumbs,
    }),
  );
