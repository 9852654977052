export default [
  {
    fields: [
      {
        label: 'URL adresa',
        name: 'url',
        placeholder: 'napr. instagram.com/nazov',
      },
    ],
    label: 'Instagram',
    value: 'instagram',
    icon: 'instagram',
  },
  {
    fields: [
      {
        label: 'URL adresa',
        name: 'url',
        placeholder: 'napr. facebook.com/nazov',
      },
    ],
    label: 'Facebook',
    value: 'facebook',
    icon: 'facebook',
  },
  {
    fields: [
      {
        label: 'URL adresa',
        name: 'url',
        placeholder: 'napr. linkedin.com/in/nazov',
      },
    ],
    label: 'LinkedIn',
    value: 'linkedin',
    icon: 'linkedin',
  },
  {
    fields: [
      {
        label: 'URL adresa',
        name: 'url',
        placeholder: 'napr. twitter.com/nazov',
      },
    ],
    label: 'Twitter',
    value: 'twitter',
    icon: 'twitter',
  },
  {
    fields: [
      {
        label: 'URL adresa',
        name: 'url',
        placeholder: 'napr. youtube.com/channel/nazov',
      },
    ],
    label: 'YouTube',
    value: 'youtube',
    icon: 'youtube',
  },
  {
    fields: [
      {
        label: 'URL adresa',
        name: 'url',
        placeholder: 'napr. livestream.com/nazov',
      },
    ],
    label: 'Livestream',
    value: 'livestream',
    icon: 'livestream',
  },
  {
    fields: [
      {
        label: 'URL adresa',
        name: 'url',
        placeholder: 'napr. tiktok.com/@nazov',
      },
    ],
    label: 'Tiktok',
    value: 'tiktok',
    icon: 'tiktok',
  },
];
