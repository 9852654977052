import { getListResults } from '@sportnet/redux-list';
import { createSelector } from 'reselect';
import at from '@sportnet/utilities/at';
import { entitiesSelector } from '../../App/selectors';
import constants from './constants';

export const layoutEntitiesSelector = createSelector(
  entitiesSelector,
  entities => entities.layouts,
);

export const layoutsListSelector = createSelector(
  layoutEntitiesSelector,
  getListResults(constants.LIST_NAME),
  (entities, ids) => (ids ? at(entities, ids) : []),
);
