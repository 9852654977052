import { Theme, getTheme } from '@sportnet/ui/Themes/baseTheme';

export interface ITheme extends Theme {}

export const theme = getTheme({
  fontFamily: "'Barlow', sans-serif",
  textColor: '#000',
  grid: {
    gutterWidth: 12,
  },
  color: {
    fadedText: '#888',
  },
});
