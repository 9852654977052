import NotFound from '@sportnet/ui/NotFound';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getFormValues } from 'redux-form';
import ContentLoader from '../../../../components/ContentLoader';
import PagesConnector from '../../../../containers/PagesConnector';
import { IPublicSettings } from '../../../../library/Pages';
import { RootState } from '../../../../rootReducer';
import { emptyFn, __ } from '../../../../utilities';
import { settingsSelector } from '../../selectors';
import { FORM_NAME } from '../LayoutForm/constants';
import { useAuth } from '@sportnet/auth-react';

type IOwnProps = {};

const mapStateToProps = (state: RootState) => ({
  layout: getFormValues(FORM_NAME)(state),
  settings: settingsSelector(state),
});

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

const LayoutDemo: React.FC<IProps> = ({ settings, layout }) => {
  const history = useHistory();

  const { ppo: appSpace } = useAuth();

  if (!settings || !layout) {
    return <ContentLoader />;
  }

  if (settings && !settings.domain) {
    return (
      <NotFound
        title={__('Nemáte nastavenú doménu')}
        onClickAction={() => history.push(`/admin/${appSpace}/domain`)}
        actionLabel={__('Prejsť na nastavenie domény')}
      />
    );
  }

  const newSettings: IPublicSettings & { CSMAppSpace: string } = {
    CSMAppSpace: appSpace ?? '',
    ...settings,
  };
  if (layout) {
    newSettings.layouts = [{ ...layout, default: true, name: 'Default' }];
  }

  return (
    <PagesConnector
      baseUrl={`https://${settings.domain}/`}
      onClickArea={emptyFn}
      settings={newSettings}
    />
  );
};

export default connect(mapStateToProps)(LayoutDemo);
