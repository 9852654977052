import React from 'react';
import { ISectionTree, SectionId } from '../../../library/App';
import { ILayout } from '../../../library/Pages';

export interface ISectionLayoutMap {
  [sectionId: number]: string | null;
}

export interface ISectionEntry {
  _id: SectionId;
  name: string;
  path: string[];
  parent: SectionId[];

  layoutId: string;
  inherited: boolean;
}

export default (
  layouts: ILayout[],
  sectionLayoutMap: ISectionLayoutMap,
  sectionTree: ISectionTree,
): ISectionEntry[] => {
  const items = React.useMemo(() => {
    const result: ISectionEntry[] = [];

    const traverseTree = (sections: ISectionTree, namePath: string[] = []) => {
      sections.forEach(section => {
        let currentLayoutId = '';
        let layoutInherited = true;
        // create path including itself
        const path = [section._id!, ...(section.parent || [])];
        // iterate over path
        for (const sectionId of path) {
          const layoutId = sectionLayoutMap[sectionId];
          // check if layout is set for section id
          if (layoutId) {
            const layout = layouts.find(l => l._id === layoutId);
            // check if layout exists
            if (layout) {
              // set found layout
              currentLayoutId = layoutId;
              layoutInherited = section._id! !== sectionId; // if layout is not set on current section it is inherited
              break;
            }
          }
        }

        result.push({
          _id: section._id!,
          name: section.name!,
          path: namePath,
          parent: section.parent || [],
          layoutId: currentLayoutId,
          inherited: layoutInherited,
        });

        if (section.sections && section.sections.length > 0) {
          traverseTree(section.sections, [...namePath, section.name!]);
        }
      });
    };

    traverseTree(sectionTree);
    return result;
  }, [sectionTree, sectionLayoutMap, layouts]);

  return items;
};
