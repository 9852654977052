import React from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import Authorization from '../Authorization';
import { AuthProvider } from '@sportnet/auth-react';
import PpoSelector from '@sportnet/auth-react/PpoSelector';
import config from '../../config';

const App: React.FC = () => {
  const history = useHistory();
  return (
    <>
      <AuthProvider app={config.APP_ID}>
        <Route exact path="/">
          <PpoSelector
            onSelectAppspace={appSpace => {
              history.push(`/admin/${appSpace.ppo}/editor`);
            }}
          />
        </Route>
      </AuthProvider>
      <Route path="/admin/:appspace/:path?" component={Authorization} />
      <Route exact path="/admin" component={() => <Redirect to="/" />} />
    </>
  );
};

export default App;
