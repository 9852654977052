import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import config from '../../config';
import { ExtraArgumentType } from '../../configureStore';
import { INormalizedSectionTree, ISectionEntity } from '../../library/App';
import { IAdminEditSettings, IAdminSettings } from '../../library/Pages';
import { RootState } from '../../rootReducer';
import { replaceEntities } from '../App/actions';

const create = actionCreatorFactory(config.EDITOR_NS);
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadSettings = createAsync<{ appSpace: string }, IAdminSettings>(
  'LOAD_SETTINGS',
  async ({ appSpace }, dispatch, getState, { PagesApi }) => {
    return await PagesApi.adminGetSettings(appSpace);
  },
);

export const saveSettings = createAsync<
  { appSpace: string; settings: IAdminEditSettings },
  IAdminSettings
>(
  'SAVE_SETTINGS',
  async ({ appSpace, settings }, dispatch, getState, { PagesApi }) => {
    return await PagesApi.adminEditSettings(appSpace, {}, settings);
  },
);

export const deleteSettingsSegment = createAsync<
  { appSpace: string; settingsSegment: keyof IAdminEditSettings },
  IAdminSettings
>(
  'DELETE_SETTINGS_SEGMENT',
  async ({ appSpace, settingsSegment }, dispatch, getState, { PagesApi }) => {
    return await PagesApi.adminDeleteSettingsSegment(appSpace, settingsSegment);
  },
);

export const loadSectionTree = createAsync<
  { appSpace: string },
  INormalizedSectionTree
>('LOAD_SECTION_TREE', async ({ appSpace }, dispatch, getState, { CmsApi }) => {
  const response = await CmsApi.getAdminSectionsTree(
    config.APP_ID,
    appSpace,
    config.DEFAULT_CONTENT_DIVIDER,
  );

  const entities: { [key: string]: ISectionEntity } = {};

  const normalizeTree = (
    sections: (typeof response)['tree'],
  ): INormalizedSectionTree => {
    return sections!.map((section: any) => {
      entities[section._id] = section;

      if (section.sections && section.sections.length > 0) {
        const normalizedSections = normalizeTree(section.sections);
        entities[section._id].sections = normalizedSections.map(
          (s: any) => s._id,
        );
        return {
          _id: section._id,
          sections: normalizedSections,
        };
      }

      return {
        _id: section._id,
      };
    });
  };
  const result = normalizeTree(response.tree); // @TODO csm/api optional

  dispatch(
    replaceEntities({
      sections: entities,
    }),
  );

  return result;
});
