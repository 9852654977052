import * as React from 'react';
import { debounce } from '../../utilities';

interface IOwnProps {
  baseUrl: string;
  editModeEnabled?: boolean;
  onClickArea: (id: string) => void;
  settings?: any;
}

type IProps = IOwnProps;

const C: React.FC<IProps> = ({
  baseUrl,
  editModeEnabled = false,
  onClickArea,
  settings = {},
}) => {
  const settingsRef = React.useRef<any>(settings);
  settingsRef.current = settings;
  const iframeEl = React.useRef<HTMLIFrameElement>(null);

  function postMessage(type: string, payload = {}) {
    if (iframeEl && iframeEl.current && iframeEl.current.contentWindow) {
      iframeEl.current.contentWindow.postMessage(
        {
          type,
          payload,
        },
        '*',
      );
    }
  }

  const postMessageDebounced = React.useMemo(() => {
    return debounce(postMessage, 200);
  }, []);

  React.useEffect(() => {
    postMessageDebounced('UPDATE_SETTINGS', settings);
  }, [settings, postMessageDebounced]);

  const handleMessage = React.useCallback(
    (message: MessageEvent) => {
      console.log('[pages connector] <<<', message.data);
      if (message.data.type === 'READY') {
        postMessage('UPDATE_SETTINGS', settingsRef.current);
      } else if (message.data.type === 'CLICK_AREA') {
        onClickArea(message.data.payload);
      }
    },
    [onClickArea],
  );

  React.useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  React.useEffect(() => {
    postMessage('SET_EDIT_MODE', editModeEnabled);
  }, [editModeEnabled]);

  return (
    <iframe
      frameBorder="0"
      ref={iframeEl}
      style={{
        height: '100%',
        width: '100%',
        flex: '1 0 0',
      }}
      src={baseUrl}
      title="Pages Preview"
    />
  );
};

export default C;
